import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { addCommasToNumber } from "../../../../../utils/helpers"
import PercentageChange from "./PercentageChange"

export function Card({ children, title, measurementType, value, percentageChange=0, width = "none" }) {
    return (
        <Box width={width} rounded="lg" bg="container.background.gray" px="20px" py="16px">
            <Flex direction="column" gap={1}>
                <Flex gap={2} className="cardComponent">
                    {children}
                    <Text className="cardTitle" color="text.secondary" fontFamily="Inter" fontWeight={400} fontSize="12px">{title}</Text>
                </Flex>
                <Flex gap={3} justifyContent="space-between">
                    <Flex gap="2" alignItems="flex-end">
                        <Text color="text.dark" fontSize="16px" fontWeight="700">
                            {addCommasToNumber(value)}
                        </Text>
                        <Text fontWeight={400} fontSize={12}>{measurementType}</Text>
                    </Flex>
                    <PercentageChange percentageChange={percentageChange}/>
                </Flex>
            </Flex>
        </Box>
    )
}
import { createIcon } from '@chakra-ui/icons'

const TempDownIcon = createIcon({
  displayName: 'TempDownIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <g clipPath="url(#clip0_5377_19896)">
        {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6425 4.53052C22.0671 4.1059 22.7556 4.1059 23.1802 4.53053L26.6627 8.01308L30.1452 4.53053C30.5698 4.1059 31.2583 4.1059 31.6829 4.53052C32.1075 4.95514 32.1075 5.64359 31.6829 6.06821L27.4315 10.3196C27.2276 10.5235 26.9511 10.6381 26.6627 10.6381C26.3743 10.6381 26.0978 10.5235 25.8938 10.3196L21.6425 6.06821C21.2179 5.64359 21.2179 4.95514 21.6425 4.53052ZM21.6425 12.3743C22.0671 11.9496 22.7556 11.9497 23.1802 12.3743L26.6627 15.8568L30.1452 12.3743C30.5698 11.9497 31.2583 11.9496 31.6829 12.3743C32.1075 12.7989 32.1075 13.4873 31.6829 13.912L27.4315 18.1634C27.2276 18.3673 26.9511 18.4818 26.6627 18.4818C26.3743 18.4818 26.0978 18.3673 25.8938 18.1634L21.6425 13.912C21.2179 13.4873 21.2179 12.7989 21.6425 12.3743ZM21.6425 21.0735C22.0671 20.6489 22.7556 20.6489 23.1802 21.0735L26.6627 24.5561L30.1452 21.0735C30.5698 20.6489 31.2583 20.6489 31.6829 21.0735C32.1075 21.4981 32.1075 22.1866 31.6829 22.6112L27.4315 26.8626C27.2276 27.0665 26.9511 27.1811 26.6627 27.1811C26.3743 27.1811 26.0978 27.0665 25.8938 26.8626L21.6425 22.6112C21.2179 22.1866 21.2179 21.4981 21.6425 21.0735Z"
        fill="#C6E3FD"
      /> */}
        <path
          d="M8.51923 29.8254C11.4706 29.8254 13.864 27.4321 13.864 24.4807C13.864 22.571 12.8614 20.8951 11.3553 19.9495L11.3556 5.0101C11.3556 3.44312 10.0855 2.17383 8.51934 2.17383C6.95319 2.17383 5.68311 3.44312 5.68311 5.0101L5.68318 19.9495C4.17708 20.8951 3.17461 22.571 3.17461 24.4807C3.17461 27.4321 5.56788 29.8254 8.51923 29.8254ZM8.51923 32C4.37312 32 1 28.6268 1 24.4807C1 22.3063 1.91944 20.2863 3.50857 18.8717V5.01073C3.50857 2.2478 5.75634 0 8.51923 0C11.2821 0 13.5299 2.2478 13.5299 5.01073V18.8717C15.119 20.2863 16.0385 22.3063 16.0385 24.4807C16.0385 28.6268 12.6653 32 8.51923 32Z"
          fill="#157FEE"
        />
        <path
          d="M9.73018 22.4766V10.9815C9.73018 10.1334 9.36159 10.2628 8.5124 10.2628C7.66322 10.2628 7.29462 10.1323 7.29462 10.9815V22.456C6.79151 22.7224 6.39162 23.1489 6.15823 23.6682C5.92484 24.1874 5.87129 24.7696 6.00605 25.3227C6.14082 25.8758 6.4562 26.3682 6.90228 26.7219C7.34836 27.0756 7.89965 27.2703 8.46891 27.2754C9.03816 27.2805 9.59284 27.0956 10.0452 26.7499C10.4975 26.4043 10.8216 25.9177 10.9662 25.3671C11.1109 24.8164 11.0677 24.2333 10.8437 23.71C10.6196 23.1867 10.2274 22.7531 9.7291 22.4777"
          fill="#157FEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_5377_19896">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})

export default TempDownIcon

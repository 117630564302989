import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Flex,
} from '@chakra-ui/react'
import html2pdf from 'html2pdf.js'
import { ILocationResult } from '../../api/locations'
import { IReportPdfResponse } from '../../api/trendReportPdf'
import { ReportPeriodType } from '../GenerateReport'
import React, { useState } from 'react'
import { EnergyConsumptionReportPdf } from './EnergyConsumptionReportPdf'
import SendEmail from './SendEmail'
import RecurringReports from './RecurringReports'
import BookmarkReport from './BookmarkReport'

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    background: 'gray.200',
    borderRadius: '0 0.25em 0.25em 0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'blue.500',
    borderRadius: '0.25em',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
}

type PreviewDocumentDialogProps = {
  locations: ILocationResult[]
  startDate: any
  endDate: any
  reportType: number
  reportName: string
  pdfReportData: IReportPdfResponse
  reportPeriodType: ReportPeriodType
  groupId: any[]
  deviceIds: any[]
  ibexIndex: number
  carbonIntensity: number
  deviceTypes: string[]
  energyType: number
  measurementTypes: string[]
  totalDetailed: number
  isOpen: boolean
  onClose: () => void
}

const PreviewDocumentDialog = (props: PreviewDocumentDialogProps) => {
  const {
    startDate,
    endDate,
    locations,
    reportType,
    reportName,
    pdfReportData,
    reportPeriodType,
    groupId,
    deviceIds,
    ibexIndex,
    carbonIntensity,
    isOpen,
    onClose,
    deviceTypes,
    energyType,
    measurementTypes,
    totalDetailed,
  } = props

  const [isDownloading, setIsDownloading] = useState(false)
  const [pdfData, setPdfData] = useState(null)

  const onDownload = async () => {
    const component = document.getElementById('pdf-container');
    if (!component) return;

    const options = {
      margin: 0,
      filename: `${reportName}.pdf`,
      pagebreak: {
        mode: "avoid-all",
        before: "#reportBody",
      },
    };

    const applyStyles = (selector: string, styles: Record<string, string>) => {
      const elements = component.querySelectorAll(selector) as unknown as HTMLElement[];
      elements.forEach((el) => {
        Object.assign(el.style, styles);
      });
      return elements;
    };

    // Style adjustments before generating the PDF
    const elementAdjustments = [
      { selector: '.locationName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.cardTitle', styles: { transform: 'translate(0%, -40%)' } },
      { selector: '.arrowIconClass', styles: { transform: 'translate(0%, 15%)' } },
      { selector: '.groupName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.groupPercentage', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.dot', styles: { marginTop: '15px' } },
      { selector: '.donutChart', styles: { transform: 'translate(0%, -25%)' } },
    ];

    const originalStyles = elementAdjustments.map(({ selector, styles }) => ({
      selector,
      elements: applyStyles(selector, styles),
      styles,
    }));

    setTimeout(() => {
      setIsDownloading(true);
    }, 0);

    try {
      const pdfBlob = await html2pdf()
        .set(options)
        .from(component)
        .outputPdf('blob')

      const pdfUrl = URL.createObjectURL(pdfBlob)
      const a = document.createElement('a')
      a.href = pdfUrl
      a.download = `${reportName}.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      setPdfData(pdfBlob);
    } catch (error) {
      console.error('PDF generation failed:', error);
    } finally {
      originalStyles.forEach(({ elements, styles }) => {
        elements.forEach((el) => {
          Object.keys(styles).forEach((styleKey) => {
            el.style[styleKey as any] = '';
          });
        });
      });
      setIsDownloading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={840}
        height={800}
        overflow={'hidden auto'}
        sx={{
          ...scrollBarStyle,
        }}
      >
        <ModalHeader pb={0} sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            pb={'0.5em'}
          >
            <Text>Preview document</Text>
            <Flex gap={5}>
              <SendEmail
                reportType='pdf'
                reportName={reportName}
                reportData={pdfData}
                disabled={!pdfData}
                startDate={startDate}
                endDate={endDate}
                tooltip='Please first download the pdf!'
              />
              <RecurringReports
                startDate={startDate}
                endDate={endDate}
                disabled={!pdfData}
                locations={locations}
                deviceGroups={groupId}
                deviceTypes={deviceTypes}
                energyType={energyType}
                reportType={reportType}
                measurementTypes={measurementTypes}
                devices={deviceIds}
                totalDetailed={totalDetailed}
                ibexPrice={ibexIndex}
                carbonIntensity={carbonIntensity}
                reportName={reportName}
                tooltip='Please first download the pdf!'
                savedAs="csv"
              />
              <BookmarkReport
                startDate={startDate}
                endDate={endDate}
                // disabled={!pdfData}
                locations={locations}
                deviceGroups={groupId}
                deviceTypes={deviceTypes}
                energyType={energyType}
                reportType={reportType}
                measurementTypes={measurementTypes}
                devices={deviceIds}
                totalDetailed={totalDetailed}
                ibexPrice={ibexIndex}
                carbonIntensity={carbonIntensity}
                reportName={reportName}
                tooltip='Please first download the pdf!'
                filters_type='bookmark'
                savedAs='csv'
              />
              <Button
                colorScheme="blue"
                size={'small'}
                sx={{ width: '150px' }}
                onClick={onDownload}
              >
                <Text fontSize={'md'}>Download</Text>
              </Button>
            </Flex>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody height={'fit-content'} p={0}>
          <Stack
            height="100%"
            // p={'1em'}
            width="100%"
            sx={{ boxSizing: 'bored-box' }}
            id={'pdf-container'}
          >
            {reportType === 3 &&
              <EnergyConsumptionReportPdf
                group_id={groupId}
                device_ids={deviceIds}
                startDate={startDate}
                endDate={endDate}
                locations={locations}
                ibexIndex={ibexIndex}
                carbonIntensity={carbonIntensity}
                isDownloading={isDownloading}
              />
            }
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PreviewDocumentDialog

import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Flex,
  Stack,
  Text,
  useMediaQuery,
  Grid,
  GridItem,
  Skeleton,
  Link,
} from '@chakra-ui/react'
import Header from '../../../app/components/Header'
import { LocationTabStrip } from '../components/LocationTabStrip'
import { useQueryParams } from '../hooks/useQueryParams'
import { DEFAULTPERIOD, PERIODPARAMS } from '../consts'
import {
  useGetConsumptionPriceByLocationIdQuery,
  useGetCurrentLocationsDataQuery,
  useGetDashboardLocationQuery,
  useGetLocationChartDataQuery,
} from '../api/dasboardApi'
import { PeriodTabStrip } from '../components/PeriodTabStrip'
import { ConsumedEnergyIcon } from '../../../assets/icons/ConsumedEnergy'
import { addCommasToNumber } from '../../../utils/helpers'
import {
  CELSIUM,
  CURRENT_USAGE_POLLING_INTERVAL,
  ENERGY_GENERATOR,
  ENERGY_PLANT_LOGGER,
  HUMIDITY,
  KILOWATT,
  KILOWATT_HOUR,
  MONETARY_VALUE,
} from '../../../utils/consts'
import { RechartsAreaChart } from '../../../app/components/AreaChart'
import { DashboardDevice } from '../types'
import DevicesContainer from '../components/DevicesContainer'
import useRedirectOn404 from '../../../app/hooks/useRedirectTo404'
import Flippable from '../components/Flippable'
import ProducedEnergyIcon from '../../../assets/icons/ProducedEnergyIcon'
import CostForConsumptionIcon from '../../../assets/icons/CostConsumptionIcon'
import GeneratedIncomeIcon from '../../../assets/icons/GeneretedIncomeIcon'
import { AverageLineChart } from '../../../app/components/AverageLineChart'
import TempDownIcon from '../../../assets/icons/TempDownIcon'
import HumidityDownIcon from '../../../assets/icons/HumidityDownIcon'

const DashboardLocation: React.FC<{
  locations: any
  overviewFetching: boolean
}> = ({ locations, overviewFetching }) => {
  const { locationId } = useParams()
  const [queryParams] = useQueryParams(PERIODPARAMS)

  const locationFlag = locationId
  const dashboardLocationData = useGetDashboardLocationQuery(
    { ...DEFAULTPERIOD, ...queryParams, location: Number(locationId) },
    { skip: !locationFlag },
  )
  const { data: energyPrice } = useGetConsumptionPriceByLocationIdQuery({
    ...DEFAULTPERIOD,
    ...queryParams,
    location: locationId,
  })

  const {
    currentData: location,
    isFetching: isLoading,
    error: getLocationError,
  } = dashboardLocationData
  useRedirectOn404(getLocationError, 'Location not found')

  const { data: currentLocationsData = [] } = useGetCurrentLocationsDataQuery(
    {
      locationIds: [Number(locationId)],
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
    },
  )

  const { mainDevices, notMainDevices } = useMemo(() => {
    const mainDevices: DashboardDevice[] = []
    const notMainDevices: DashboardDevice[] = []
    const devicesArr = location?.devices || []
    devicesArr.forEach((device) => {
      if (device.is_main) mainDevices.push(device)
      else notMainDevices.push(device)
    })

    return { mainDevices, notMainDevices }
  }, [location?.devices])

  const mainDevice = mainDevices[0]

  const { data: getDeviceReportDataResult, isFetching: isDeviceReportDataFetching } =
    useGetLocationChartDataQuery({
      ...DEFAULTPERIOD,
      ...queryParams,
      location: locationId,
    })

  const deviceReportData = getDeviceReportDataResult?.filter(Boolean)

  const generators = useMemo(() => {
    return notMainDevices
      .filter(
        (device) =>
          device.category === ENERGY_GENERATOR || device.category === ENERGY_PLANT_LOGGER,
      )
      .sort((a, b) => b.total_generated_power! - a.total_generated_power!)
  }, [notMainDevices])

  const consumers = useMemo(() => {
    return notMainDevices.filter((device) => !generators.includes(device))
  }, [notMainDevices, generators])

  const chartDataKeys = []

  if (consumers.length > 0) {
    chartDataKeys.push({
      key: 'consumption',
      displayValue: 'Total Consumption',
    })
  }
  if (generators.length > 0) {
    chartDataKeys.push({
      key: 'generated_power',
      displayValue: 'Total Production',
    })
  }

  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)')
  const HeaderContent = <Header title="Overview" />

  const dynamicTitle = useCallback(() => {
    const titleParts = [
      consumers.length > 0 ? 'Total Consumption' : '',
      generators.length > 0 ? 'Total Production' : '',
    ].filter(Boolean)

    return titleParts.join(' and ')
  }, [consumers, generators])

  return (
    <>
      {!isSmallerThan1200 && HeaderContent}

      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}>
        <GridItem>
          <LocationTabStrip
            data={locations?.filter(({ parent_id }) => parent_id === null)}
            clearNavigationPath="dashboard"
            handleNavigationPath="dashboard/location"
          />
        </GridItem>
        <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box py="15px">
            <PeriodTabStrip />
          </Box>
        </GridItem>
      </Grid>

      <Stack w="full" spacing={5}>
        {locationFlag ? (
          <>
            <Flex
              flexGrow={1}
              p="4"
              rounded="lg"
              alignItems="flex-start"
              flexDirection="column"
              bgColor="white"
              w={{ base: '100%', lg: 'auto' }}
            >
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                gap={4}
                w="full"
                py="16px"
              >
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ConsumedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Total consumed energy</Text>

                          <Flex gap="2" align="center">
                            <Text color="text.dark" fontSize="20px" fontWeight="700">
                              {addCommasToNumber(location?.total_consumption || 0)}
                            </Text>
                            <Text>{KILOWATT_HOUR}</Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ConsumedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Current Usage</Text>

                          <Flex gap="2" align="center">
                            <Flippable
                              value={
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(
                                    currentLocationsData[locationId]?.consumption || 0,
                                  )}
                                </Text>
                              }
                              unit={KILOWATT}
                              hovered
                              hoveredBgColor="container.background.gray"
                              topMargin={2}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem>
                  <Skeleton isLoaded={!isLoading}>
                    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
                      <Flex align="center" gap="16px">
                        <ProducedEnergyIcon boxSize="32px" color="text.secondary" />
                        <Flex direction="column">
                          <Text color="text.secondary">Produced energy</Text>
                          <Flex gap="2" align="center">
                            <Text color="text.dark" fontSize="20px" fontWeight="700">
                              {addCommasToNumber(location?.total_generated_power || 0)}
                            </Text>
                            <Text>{KILOWATT_HOUR}</Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                  </Skeleton>
                </GridItem>
                <GridItem colSpan={3}>
                  <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                    <GridItem>
                      <Skeleton isLoaded={!isLoading}>
                        <Box
                          rounded="lg"
                          bg="container.background.gray"
                          px="20px"
                          py="16px"
                        >
                          <Flex align="center" gap="16px">
                            <CostForConsumptionIcon
                              boxSize="32px"
                              color="text.secondary"
                            />
                            <Flex direction="column">
                              <Text color="text.secondary">Cost for consumed energy</Text>
                              <Flex gap="2" align="center">
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(energyPrice?.total_cost || 0)}
                                </Text>
                                <Text>{MONETARY_VALUE}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </Skeleton>
                    </GridItem>
                    <GridItem>
                      <Skeleton isLoaded={!isLoading}>
                        <Box
                          rounded="lg"
                          bg="container.background.gray"
                          px="20px"
                          py="16px"
                        >
                          <Flex align="center" gap="16px">
                            <GeneratedIncomeIcon boxSize="32px" color="text.secondary" />
                            <Flex direction="column">
                              <Text color="text.secondary">
                                Generated income through renewable
                              </Text>
                              <Flex gap="2" align="center">
                                <Text color="text.dark" fontSize="20px" fontWeight="700">
                                  {addCommasToNumber(
                                    energyPrice?.total_generated_income || 0,
                                  )}
                                </Text>
                                <Text>{MONETARY_VALUE}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </Skeleton>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem colSpan={3}>
                  <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                    {deviceReportData?.length > 0 ? (
                      <>
                        <GridItem>
                          <Skeleton isLoaded={!isLoading}>
                            <AverageLineChart
                              data={deviceReportData}
                              period={queryParams['period'] || 'day'}
                              lineProps={{
                                dataKey: 'max_temperature',
                                type: 'ss',
                              }}
                              title={'Temperature'}
                              measurementUnit={CELSIUM}
                              icon={<TempDownIcon />}
                            />
                          </Skeleton>
                        </GridItem>
                        <GridItem>
                          <Skeleton isLoaded={!isLoading}>
                            <AverageLineChart
                              data={deviceReportData}
                              period={queryParams['period'] || 'day'}
                              lineProps={{
                                dataKey: 'humidity',
                                type: 'ss',
                              }}
                              title={'Humidity'}
                              measurementUnit={HUMIDITY}
                              icon={<HumidityDownIcon />}
                            />
                          </Skeleton>
                        </GridItem>
                      </>
                    ) : null}
                  </Grid>
                </GridItem>
              </Grid>
            </Flex>

            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap={4}
              w="full"
            >
              <GridItem>
                <Flex direction="column" gap="4">
                  {generators.length ? (
                    <Box bg="white" rounded="lg">
                      <Skeleton
                        isLoaded={!isLoading}
                        height={isLoading ? '369px' : 'auto'}
                      >
                        <DevicesContainer
                          devices={generators || []}
                          totalConsumption={generators.reduce(
                            (accumulator, generator) => {
                              return accumulator + generator.total_generated_power
                            },
                            0,
                          )}
                          title="Generators"
                          type="generator"
                          locationId={locationId}
                        />
                      </Skeleton>
                    </Box>
                  ) : null}

                  <Box bg="white" rounded="lg">
                    <Skeleton isLoaded={!isLoading} height={isLoading ? '369px' : 'auto'}>
                      <DevicesContainer
                        title="Consumers"
                        devices={consumers}
                        totalConsumption={location?.total_consumption}
                        type="consumers"
                        locationId={locationId}
                      />
                    </Skeleton>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem>
                <Skeleton
                  isLoaded={!isDeviceReportDataFetching && !isLoading}
                  height={isDeviceReportDataFetching || isLoading ? '369px' : 'auto'}
                >
                  {deviceReportData?.length > 0 ? (
                    <Box bg="white" rounded="lg" px="20px" py="16px">
                      <>
                        {mainDevice?.id ? (
                          <Link
                            href={`/deviceInfo?location=${locationId}&device=${mainDevice?.id}`}
                          >
                            <Text
                              pl="10px"
                              pb="16px"
                              fontSize="14px"
                              fontWeight="700"
                              color="text.secondary"
                            >
                              {mainDevice.title}
                            </Text>
                          </Link>
                        ) : (
                          dynamicTitle()
                        )}

                        <RechartsAreaChart
                          data={deviceReportData}
                          period={queryParams['period'] || 'day'}
                          dataKeys={chartDataKeys}
                        />
                      </>
                    </Box>
                  ) : null}
                </Skeleton>
              </GridItem>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </>
  )
}

export default DashboardLocation

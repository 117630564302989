import { createIcon } from '@chakra-ui/icons'

const CarbonIntensityIcon = createIcon({
  displayName: 'CarbonIntensityIcon',
  viewBox: '0 0 32 32',
  path: (
    <g id="Icons/carbon-intensity">
      <path
        id="Vector"
        d="M28.6597 21.5008C29.2972 20.2601 29.49 18.837 29.2057 17.4705C28.9215 16.1039 28.1774 14.8771 27.0984 13.996C26.0194 13.1149 24.6713 12.6331 23.2802 12.6314H21.7529C21.3687 11.1383 20.6364 9.75822 19.6163 8.60489C18.5963 7.45157 17.3177 6.55803 15.886 5.99794C14.4542 5.43784 12.9104 5.22722 11.3816 5.38344C9.85287 5.53966 8.38299 6.05824 7.09316 6.89644C5.80332 7.73465 4.73045 8.86847 3.96299 10.2045C3.19552 11.5404 2.75543 13.0403 2.679 14.5805C2.60256 16.1207 2.89196 17.657 3.52335 19.0629C4.15474 20.4688 5.11003 21.704 6.3105 22.6667"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector_2"
        d="M22.0012 22.1666H21.1615C20.9121 21.3214 20.3969 20.5543 19.6743 19.9525C18.9517 19.3508 18.0508 18.9385 17.0738 18.7625C16.0969 18.5865 15.0832 18.6538 14.1478 18.9569C13.2124 19.26 12.3929 19.7866 11.7824 20.4769C11.1719 21.1673 10.7949 21.9937 10.6942 22.8622C10.5935 23.7307 10.7732 24.6065 11.2128 25.3901C11.6524 26.1737 12.3343 26.8337 13.1811 27.295C14.0278 27.7564 15.0054 28.0006 16.0029 28H22.0012C22.885 28 23.7327 27.6927 24.3576 27.1457C24.9826 26.5987 25.3337 25.8568 25.3337 25.0833C25.3337 24.3097 24.9826 23.5679 24.3576 23.0209C23.7327 22.4739 22.885 22.1666 22.0012 22.1666Z"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

export default CarbonIntensityIcon

import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { OutlinedDeleteIcon } from '../../../assets/icons/OutlinedDeleteIcon'
import { useDeleteDeviceGroupMutation } from '../api/deviceGroupConfigurationApi'

type Props = {
  groupId: number
}
export const DeleteDeviceGroupModal = ({groupId}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [trigger] = useDeleteDeviceGroupMutation();
  const onConfirm = async () => {
    await trigger({id: groupId})
    onClose()
  }
  return (
    <>
      <IconButton
        background="none"
        aria-label="delete device group"
        size="md"
        _focus={{ boxShadow: 'none' }}
        icon={<OutlinedDeleteIcon />}
        onClick={onOpen}
      />

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete device group</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text> Are sure u want to delete this group </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onConfirm}
              colorScheme="blue"
              mr={3}
            >
              Confirm
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import { Flex, Spinner, Stack } from "@chakra-ui/react"
import React from "react"
import { ILocationResult } from "../../api/locations"
import { EnergyReportTitlePage } from "./EnergyReportComponents/EnergyReportTitlePage"
import { EnergyReportBody } from "./EnergyReportComponents/EnergyReportBody"
import backgroundImg from '../../../../assets/report/background.png'
import { useGetEnergyConsumptionReportQuery } from "../../api/reportsApi"
import { ReportData } from "../../types"

type Props = {
  locations: ILocationResult[] | string[]
  startDate: Date;
  endDate: Date;
  group_id: any[],
  device_ids: any[],
  ibexIndex: number,
  carbonIntensity: number,
  isDownloading: boolean
}

export const EnergyConsumptionReportPdf = ({ locations, startDate, endDate, device_ids, group_id, ibexIndex, carbonIntensity, isDownloading }: Props) => {
  const isLocationResultArray = (arr: typeof locations): arr is ILocationResult[] => {
    return arr.length > 0 && typeof (arr as ILocationResult[])[0]?.id !== 'undefined';
  };

  const locationsIds = isLocationResultArray(locations)
    ? locations.map((x) => x.id.toString())
    : locations;
  const groupsIds = group_id?.map((x) => x.id?.toString()) || []
  const deviceIDs = device_ids?.map((x) => x.toString()) || []
  const energyConsumptionParameters: ReportData.QueryParams = {
    from_date: startDate.toLocaleDateString('en-US'),
    to_date: endDate.toLocaleDateString('en-US'),
    location_ids: locationsIds,
    groups_id: groupsIds,
    device_ids: deviceIDs,
    ibex_index: ibexIndex?.toString(),
    carbon_intensity: carbonIntensity?.toString(),
  }
  const { data, isLoading } = useGetEnergyConsumptionReportQuery(energyConsumptionParameters)
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      backgroundImage={`url('${backgroundImg}')`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="left"
    >
      <Flex direction="column" justifyContent="center" alignItems="center" width="100%" height="100%">
        {isLoading || isDownloading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        ) :
          <>
            <EnergyReportTitlePage startDate={startDate} endDate={endDate} locations={data} />
            <EnergyReportBody startDate={startDate} endDate={endDate} locations={data} />
          </>}
      </Flex>
    </Stack>
  )
}
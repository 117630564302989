import React from 'react'
import { UseControllerProps, useController } from 'react-hook-form'

import { Select, Props } from 'chakra-react-select'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'

type ControlledSelectProps = UseControllerProps<any> &
  Props & {
    label: string
  }

export const ControlledSelect = ({
  control,
  name,
  id,
  label,
  rules,
  ...props
}: ControlledSelectProps) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController<any>({
    name,
    control,
    rules,
  })

  return (
    <FormControl py={4} isInvalid={!!error} id={id}>
      <Select
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}

import { createIcon } from '@chakra-ui/icons'

const CloudIcon = createIcon({
  displayName: 'CloudIcon',
  viewBox: '0 0 32 32',
  path: (
    <g id="Icons/cloud">
      <path
        id="Vector"
        d="M23.2747 13.9374H21.7478C21.2945 12.1815 20.3577 10.588 19.0439 9.3379C17.7301 8.08782 16.0921 7.23131 14.3158 6.86569C12.5396 6.50008 10.6964 6.64002 8.99573 7.2696C7.29505 7.89919 5.80506 8.99319 4.69507 10.4273C3.58509 11.8614 2.89961 13.5781 2.71653 15.3823C2.53345 17.1865 2.8601 19.0059 3.65938 20.6337C4.45866 22.2615 5.69852 23.6325 7.23806 24.5909C8.7776 25.5493 10.5551 26.0567 12.3686 26.0553H23.2747C24.8816 26.0553 26.4228 25.417 27.559 24.2807C28.6953 23.1444 29.3337 21.6033 29.3337 19.9963C29.3337 18.3894 28.6953 16.8483 27.559 15.712C26.4228 14.5757 24.8816 13.9374 23.2747 13.9374Z"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none" // Added fill="none" to make the icon transparent
      />
    </g>
  ),
})

export default CloudIcon

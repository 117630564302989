import { useMemo } from 'react'
import SingleSelect from '../components/SingleSelect'
import { useGetMeasurementTypesQuery } from '../../locations/api/locationsApi'
import MultiSelect from '../components/MultiSelect'

type TotalReports = {
  id: number
  name: string
}

const getTotalReports = (energyType: number): TotalReports[] => {
  switch (energyType) {
    case 1:
      return [{ id: 1, name: 'Total consumed energy by device' }, { id: 2, name: 'Detail consumed energy by device' },{ id: 3, name: 'Detail consumed energy by location' }]
    case 2:
      return [{ id: 1, name: 'Total generated energy by device' }, { id: 2, name: 'Detail generated energy by device' }, { id: 3, name: 'Detail generated energy by location' }]
    default:
      return [{ id: 1, name: 'N / A' }]
  }
}

export const TrendReportFilters = ({
  selectedEnergyType,
  selectedTotalDetailed,
  setSelectedTotalDetailed,
  selectedMeasurementTypes,
  setSelectedMeasurementTypes,
  selectedDeviceTypes,
  register,
  setValue,
}) => {
  const { data: measurementTypes } = useGetMeasurementTypesQuery({
    deviceTypeIds: selectedDeviceTypes,
  })

  const measurementTypesSelect = useMemo(
    () =>
      measurementTypes?.results.map(({ id, name }) => ({
        id,
        name,
      })),
    [measurementTypes],
  )

  return (
    <>
      <SingleSelect
        key="total-detailed"
        items={getTotalReports(selectedEnergyType)}
        {...register('selectedTotalDetailed')}
        setSelectedOption={setSelectedTotalDetailed}
        selectedOption={selectedTotalDetailed}
        header="Select total report"
      />
      <MultiSelect
        items={measurementTypesSelect}
        setSelectedOptions={setSelectedMeasurementTypes}
        selectedOptions={selectedMeasurementTypes}
        setValue={setValue}
        invalidateFields={['selectedDevices']}
        header={`Measurement type/s${
          selectedMeasurementTypes.length > 0
            ? ` (${selectedMeasurementTypes.length})`
            : ''
        }`}
        tooltip={{
          label: 'Please select period',
          hasArrow: true,
          placement: 'top',
        }}
      />
    </>
  )
}

import { Grid, GridItem, Box, Text, Flex } from "@chakra-ui/react"
import React from "react"
import DonutChart from "./DonutChart"

interface Props {
    groupPercents: number[],
    groupNames: string[],
    total: number,
    percentageChange: any,
    dynamicColors: string[],
    topGroups: any,
}

export default function GroupUnfo(props: Props) {
    const { topGroups, groupPercents, groupNames, total, percentageChange, dynamicColors } = props
    return (
        <Flex width="100%" justifyContent="space-between">
            <Grid
                h='100%'
                w="65%"
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(1, 1fr)'
                gap="15px"
            >
                {topGroups?.map((group, index) => {
                    return (
                        <GridItem>
                            <Box position="relative" rounded="full" bg="#d0d6e0" px="10px" py="12px">
                                <Text className="groupName" fontWeight={700} fontSize="15px" fontFamily="Inter">{group.group_name}</Text>
                                <Box
                                    position="absolute"
                                    top="-2px"
                                    right="0px"
                                    w="50px"
                                    h="50px"
                                    bg={dynamicColors[index]}
                                    color="white"
                                    borderRadius="50%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontWeight={700}
                                    fontSize="12px"
                                >
                                    <p className="groupPercentage">{group.percentage}%</p>
                                </Box>
                            </Box>
                        </GridItem>
                    )
                })}
            </Grid>
            <DonutChart
                data={groupPercents}
                labels={groupNames}
                total={total}
                percentageChange={percentageChange}
                colors={dynamicColors}
            />
        </Flex>
    )
}
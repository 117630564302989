import { Text, Tooltip } from '@chakra-ui/react';

type Props = {
    text: string
}

export const TruncatedText = ({ text } : Props)  =>{
  if (!text || text.length === 0) return null;

  return (
    <Tooltip label={text} isDisabled={text.length <= 90}>
      <Text
        fontSize="12px"
        fontWeight="400"
        color="text.secondary"
        isTruncated
        maxWidth="200px"
      >
        {text}
      </Text>
    </Tooltip>
  );
}
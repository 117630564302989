import { Navigate, Route, Routes } from 'react-router-dom'

import { Login } from './features/auth/pages/Login'
import { PrivateRoute } from './utils/PrivateRoute'
import Dashboard from './features/dashboard//pages/Dashboard'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme/index'
import { ForgotPassword } from './features/auth/pages/ForgotPassword'
import { ResetPassword } from './features/auth/pages/ResetPassword'
import { Auth } from './features/auth/pages/Auth'
import { TokenProtectedRoute } from './utils/TokenProtectedRoute'
import { Background } from './features/auth/pages/Background'
import { Layout } from './features/Layout/pages/Layout'
import DeviceDetails from './features/dashboard/pages/DeviceDetails'
import { Main as AnalysisHome } from './features/analysis/pages'
import { AnalysisData } from './features/analysis/pages/Analysis'
import AnalysisCompare from './features/analysis/pages/Compare'
import LocationsPage from './features/locations'
import Automations from './features/automations/pages'
import AddLocation from './features/locations/AddLocation'
import Devices from './features/locations/Devices'
import AddDevice from './features/locations/AddDevice'
import EditLocation from './features/locations/EditLocation'
import Notifications from './features/notifications'
import EditDevice from './features/locations/EditDevice'
import CookieConsent from './app/components/CookieConsent'

import React from 'react'
import { Alarms } from './features/alarms/pages/Alarms'
import NotFound from './features/notFound/pages/NotFound'
import DashboardLocation from './features/dashboard/pages/DashboardLocation'
import { useGetDashboardOverviewQuery } from './features/dashboard/api/dasboardApi'
import { useQueryParams } from './features/dashboard/hooks/useQueryParams'
import { DEFAULTPERIOD, PERIODPARAMS } from './features/dashboard/consts'
import { DeviceConfiguration } from './features/deviceConfiguration/page/DeviceConfiguration'
import { ToastContainer } from 'react-toastify'
import { ReportRoutes } from './utils/ReportRoutes'

const DashboardLayout = ({ children }) => {
  const [queryParams] = useQueryParams(PERIODPARAMS)
  const querySearchParams = { ...DEFAULTPERIOD, ...queryParams }

  const dashboardOverviewData = useGetDashboardOverviewQuery({
    from: querySearchParams.from,
    to: querySearchParams.to,
    period: querySearchParams.period,
  })
  const { data: { results: locations } = { results: [] }, isFetching: overviewFetching } =
    dashboardOverviewData

  // Ensure children is a function and call it with the locations data
  return typeof children === 'function' ? children({ locations, overviewFetching }) : null
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ToastContainer newestOnTop />
      <Routes>
        <Route element={<Background />}>
          <Route path="login" element={<Login />} />
          <Route element={<Auth />}>
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="reset-password"
              element={
                <TokenProtectedRoute redirectTo="/forgot-password">
                  <ResetPassword />
                </TokenProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/" element={<PrivateRoute redirectTo="/login" />}>
          <Route element={<Layout />}>
            <Route index element={<Navigate to="/dashboard" />}></Route>
            <Route
              path="dashboard"
              element={
                <DashboardLayout>
                  {({ locations, overviewFetching }) => (
                    <Dashboard
                      locations={locations}
                      overviewFetching={overviewFetching}
                    />
                  )}
                </DashboardLayout>
              }
            ></Route>
            <Route
              path="dashboard/location/:locationId"
              element={
                <DashboardLayout>
                  {({ locations, overviewFetching }) => (
                    <DashboardLocation
                      locations={locations}
                      overviewFetching={overviewFetching}
                    />
                  )}
                </DashboardLayout>
              }
            ></Route>
            <Route
              path="alarms"
              element={
                <DashboardLayout>
                  {({ locations }) => <Alarms locations={locations} />}
                </DashboardLayout>
              }
            ></Route>
            <Route path="analysis">
              <Route index element={<AnalysisHome />} />
              <Route path="data-analysis" element={<AnalysisData />} />
              <Route path="compare-analysis" element={<AnalysisCompare />} />
            </Route>
            <Route path="locations">
              <Route index element={<LocationsPage />} />
              <Route path="add-location" element={<AddLocation />} />
              <Route path="add-device" element={<AddDevice />} />
              <Route path=":id/devices" element={<Devices />} />
              <Route path=":id/edit" element={<EditLocation />} />
              <Route path="device/:id/edit" element={<EditDevice />} />
            </Route>
            <Route path="automations">
              <Route index element={<Automations />} />
            </Route>
            <Route path="reports/*" element={<ReportRoutes />} />
            <Route path="deviceInfo" element={<DeviceDetails />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="deviceConfiguration" element={<DeviceConfiguration />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
      <CookieConsent />
    </ChakraProvider>
  )
}

export default App

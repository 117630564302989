import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react'
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom'
import { useLazyGetProfileQuery, useLoginMutation } from '../api/authApi'
import { Logo } from '../../../assets/icons/Logo'
import MethodiaLogo from '../../../assets/icons/Methodia'
import { LoginRequest } from '../types'
import { useUser } from '../../../context/UserContext'
import { setMockUser } from '../../../mockData/mockUsers'
import { getSubdomain, METHODIA_SUBDOMAIN } from '../../../utils/helpers'

interface stateType {
  from?: { pathname: string; search?: string }
}

export const Login: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<LoginRequest>()

  const [login, { isLoading, isError }] = useLoginMutation()
  const [showAlert, setShowAlert] = useState<Boolean>(false)

  const { setUser, user } = useUser()

  const navigate = useNavigate()

  const locationState = useLocation().state as stateType
  const from = locationState?.from?.pathname || '/'
  const search = locationState?.from?.search || ''

  const [getProfile] = useLazyGetProfileQuery()

  useEffect(() => {
    if (user) {
      navigate(from + search, { replace: true })
    }
  }, [user, from, search, navigate])

  useEffect(() => {
    return setShowAlert(isError)
  }, [isError])

  useEffect(() => {
    const subscription = watch(() => setShowAlert(false))
    return () => subscription.unsubscribe()
  }, [watch])

  const [showPassword, setShowPassword] = React.useState<Boolean>(false)

  async function onSubmit(values: LoginRequest) {
    const existingToken = window.localStorage.getItem('aiot_t')

    if (existingToken) {
      window.localStorage.removeItem('aiot_t')
    }

    await login(values)
      .unwrap()
      .then(({ token }) => {
        window.localStorage.setItem('aiot_t', token)
        getProfile()
          .unwrap()
          .then((user) => {
            setUser(user)
            // Used for mock data
            setMockUser(user.email)
          })
        navigate(from + search, { replace: true })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const subdomain = getSubdomain()
  return (
    <VStack justifyContent="center" w="full" h="100vh" alignContent="center" spacing={8}>
      <VStack spacing={6}>
        {subdomain === METHODIA_SUBDOMAIN ? (
          <MethodiaLogo w={300} h={100} />
        ) : (
          <Logo w={{ base: 40, sm: 'auto' }} h={{ base: 5, sm: 8 }} />
        )}
      </VStack>

      <VStack
        w={{ base: '95%', sm: '100%', md: 'lg' }}
        spacing={4}
        direction="column"
        boxShadow="lg"
        p={{ base: 4, sm: 8 }}
        rounded="lg"
        borderWidth={1}
        as="form"
        fontSize="sm"
        mx={{ base: 4 }}
        onSubmit={handleSubmit(onSubmit)}
        bgColor="white"
      >
        {showAlert && (
          <Alert status="error" rounded="lg">
            <HStack w="full">
              <AlertIcon />
              <VStack mx="auto" w="full">
                <AlertTitle>Wrong email or password</AlertTitle>
                <AlertDescription textAlign="center">
                  Please try again or reset your password.
                </AlertDescription>
              </VStack>
            </HStack>
          </Alert>
        )}

        <FormControl isInvalid={errors.email !== undefined} color="text.light">
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              w={{ base: 10, sm: 14 }}
              children={<EmailIcon color="gray.300" />}
            />
            <Input
              pl={{ base: 10, sm: 14 }}
              placeholder="Enter your email address"
              id="email"
              // value="vladimirtodorov@dimastyle.com"
              color="text.dark"
              {...register('email', {
                required: '* Required field',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: '* Invalid email address',
                },
              })}
            />
          </InputGroup>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password !== undefined}>
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              w={{ base: 10, sm: 14 }}
              children={<LockIcon color="gray.200" />}
            />
            <Input
              pl={{ base: 10, sm: 14 }}
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              id="password"
              color="text.dark"
              {...register('password', {
                required: '* Required field',
              })}
            />
            <InputRightElement>
              <Button
                size="lg"
                onClick={() => setShowPassword(!showPassword)}
                variant="unstyled"
                _focus={{ boxShadow: 'none' }}
              >
                {showPassword ? (
                  <ViewOffIcon color="gray.300" />
                ) : (
                  <ViewIcon color="gray.300" />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
          <Box w="full" textAlign="right">
            <Link color="text.light" as={ReachLink} to="/forgot-password" fontSize="xs">
              Forgot your password
            </Link>
          </Box>
        </FormControl>
        <Button
          w="full"
          colorScheme="button.primary"
          h={12}
          isLoading={isLoading}
          type="submit"
          fontSize="md"
        >
          Login
        </Button>
        <HStack spacing={4} color="text.dark">
          <Link textDecor="underline" as={ReachLink} to="/home" fontSize="sm">
            Terms and conditions
          </Link>
          <Link textDecor="underline" as={ReachLink} to="/home" fontSize="sm">
            Privacy
          </Link>
        </HStack>
        <Text fontSize="xs" fontWeight="semibold" color="text.dark">
          {new Date().getFullYear()} All rights reserved
        </Text>
      </VStack>
    </VStack>
  )
}

import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuOptionGroup,
  MenuItemOption,
  Checkbox,
  Text,
  Flex,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Tooltip,
  PlacementWithLogical,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

interface Props {
  items?: { name: string; id: number | string; }[]
  setSelectedOptions: (value: string[]) => void
  setValue?: (field: string, value: any) => void
  invalidateFields?: string[]
  selectedOptions: string[]
  header?: string
  required?: boolean
  isDisabled?: boolean
  tooltip?: {
    label: string
    hasArrow: boolean
    placement: PlacementWithLogical
  }
}

const MultiSelect: React.FC<Props> = ({ items, setSelectedOptions, setValue, invalidateFields, selectedOptions, isDisabled, tooltip, header, required }) => {
  const allItemIds = items?.map((item) => item.id.toString()) || []
  const allItemsSelected = selectedOptions.length === items?.length

  const renderWithTooltip = (children: JSX.Element) => (
    <Tooltip
      w="full"
      label={tooltip!.label}
      placement={tooltip!.placement}
      hasArrow={tooltip!.hasArrow}
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  )

  const MenuButtonComponent = (
    <MenuButton
      w="full"
      isDisabled={isDisabled}
      as={Button}
      rightIcon={<ChevronDownIcon />}
      bg="white"
      border="1px"
      height="48px"
      borderColor={ required ? "red.500" : "border.strong"}
      textAlign="left"
      fontSize="18px"
      _disabled={{ color: 'gray.400' }}
      fontWeight="normal"
    >
      {header}
    </MenuButton>
  )

  const handleChange = () => {
    invalidateFields?.forEach((field) => {
      setValue(field, '')
    })
  }

  return (
    <Menu closeOnSelect={false}>
      {isDisabled && tooltip
        ? renderWithTooltip(MenuButtonComponent)
        : MenuButtonComponent}
      <MenuList maxHeight="300px" overflowY="auto">
        {items && items.length > 0 ? (
          <>
            <MenuGroup>
              <MenuItem
                onClick={() => {
                  setSelectedOptions(allItemsSelected ? [] : allItemIds)
                  handleChange()
                }}
              >
                <Flex>
                  <Checkbox
                    isChecked={allItemsSelected}
                    isIndeterminate={selectedOptions.length > 0 && !allItemsSelected}
                    marginRight="3"
                    pointerEvents="none"
                  />
                  <Text>{allItemsSelected ? 'Clear all' : 'Select all'}</Text>
                </Flex>
              </MenuItem>
            </MenuGroup>

            <MenuDivider />
            <MenuOptionGroup
              value={selectedOptions}
              type="checkbox"
              onChange={(values: string | string[]) => {
                setSelectedOptions(Array.isArray(values) ? values : [])
                handleChange()
              }}
            >
              {items.map((item) => (
                <MenuItemOption value={item.id.toString()} key={item.id}>
                  <Text>{item.name}</Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Text>No data available</Text>
          </Flex>
        )}
      </MenuList>
    </Menu>
  )
}

export default MultiSelect

import { pythonApi } from '../../../app/services/api/api'

import { DeviceDataType, RegisterDeviceBody, UnregisteredDevice } from './types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnregisteredDeviceList: builder.query<UnregisteredDevice[], void>({
      query: () => `/devices/unregistered/`,
      providesTags: ['List'],
    }),
    postRegisteredDevice: builder.mutation<DeviceDataType, RegisterDeviceBody>({
      query: (body) => ({
        url: '/devices/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['List'],
    }),
    hardDelete: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/auto-discovery/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['List'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUnregisteredDeviceListQuery,
  usePostRegisteredDeviceMutation,
  useHardDeleteMutation,
} = extendedApi

import React, { useMemo, useState } from 'react';
import {
    IconButton,
    Flex,
    Tooltip,
    useDisclosure,
    Text,
    Button,
    Box,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { RecurringReport } from '../../../../assets/icons/RecurringReport';
import CustomModal from './CustomModal';
import { formatDate } from '../../../../utils/helpers';
import EmailInput from './EmailInput';
import { ReportData } from '../../types';
import { useCreateRecurringReportMutation } from '../../api/reportsApi';
import { useReportFilterContext } from '../../../../context/ReportFilterContext';

interface Props {
    disabled: boolean;
    startDate: any;
    endDate: any;
    locations: any;
    deviceGroups: any;
    deviceTypes: any;
    energyType: any;
    reportType: any;
    measurementTypes: any;
    devices: any;
    totalDetailed: any;
    ibexPrice: number;
    carbonIntensity: number;
    reportName: string;
    tooltip: string;
    savedAs: string;
}

const customDatePicker = ({ handleSelect }) => (
    <Box
        position="absolute"
        top="90px"
        p={3}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        zIndex="10"
        boxShadow="md"
    >
        <Grid templateColumns="repeat(7, 1fr)" gap={1}>
            {Array.from({ length: 31 }, (_, index) => (
                <GridItem display="flex" justifyContent="center">
                    <Button
                        key={index + 1}
                        onClick={() => handleSelect(index + 1)}
                        size="sm"
                        variant="outline"
                        border="none"
                    >
                        {index + 1}
                    </Button>
                </GridItem>
            ))}
        </Grid>
    </Box>
);

export default function RecurringReports(props: Props) {
    const {
        disabled,
        startDate,
        endDate,
        locations,
        deviceGroups,
        deviceTypes,
        energyType,
        reportType,
        measurementTypes,
        devices,
        totalDetailed,
        ibexPrice,
        carbonIntensity,
        reportName,
        tooltip,
        savedAs,
    } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedFrequencyButton, setSelectedFrequencyButton] = useState("Monthly");
    const [selectedRepeatButton, setSelectedRepeatButton] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [emails, setEmails] = useState([]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const {recurringTemplateCount} = useReportFilterContext()
    const [createRecurringReport, { isLoading }] = useCreateRecurringReportMutation()

    const handleDaySelect = (day: number) => {
        setSelectedDay(day);
        setIsDatePickerOpen(false);

        let suffix = "th"
        if (day === 1) {
            suffix = "st"
        } else if (day === 2) {
            suffix = "nd"
        }

        if (selectedRepeatButton === "Each") {
            setSelectedRepeatButton(`Each - ${day}${suffix}`);
        } else if (selectedRepeatButton === "On the") {
            setSelectedRepeatButton(`On the - ${day}${suffix}`);
        }
    };

    const handleCreate = () => {
        const locationsIds = locations?.map((x) => x.id.toString()).join(",")
        const groupsIds = deviceGroups?.map((x) => x.id.toString()).join(",")

        const recurringReportParams: ReportData.RecurringReportParams = {
            startDate: startDate,
            endDate: endDate,
            locations: locationsIds,
            deviceGroups: groupsIds,
            deviceTypes: deviceTypes,
            energyType: energyType,
            reportType: reportType,
            measurementTypes: measurementTypes,
            devices: devices,
            totalDetailed: totalDetailed,
            ibexPrice: ibexPrice,
            carbonIntensity: carbonIntensity,
            reportName: reportName,
            sendOn: selectedRepeatButton,
            sendTo: emails,
            frequency: selectedFrequencyButton,
            filters_type: "recurring",
            savedAs: savedAs,
        }
        createRecurringReport(recurringReportParams)
        handleClose()
    }

    const handleClose = () => {
        onClose()
        setEmails([])
        setSelectedFrequencyButton(null)
        setSelectedRepeatButton(null)
        setSelectedDay(null)
    };

    const tooltipLabel = "You have reached the limit of saved samples. To save a new one, please remove an old sample."

    const renderWithTooltip = useMemo(() => (children) => {
        return (
            <Tooltip
                w="full"
                label={tooltipLabel}
                placement="top"
                hasArrow={true}
                shouldWrapChildren
                isDisabled={recurringTemplateCount < 10}
            >
                {children}
            </Tooltip>
        );
    }, [tooltipLabel, recurringTemplateCount]);

    const IconButtonComp = (
        <IconButton
            size="sm"
            disabled={recurringTemplateCount >= 10}
            background="none"
            aria-label="send email"
            _focus={{ boxShadow: 'none' }}
            icon={<RecurringReport boxSize="24px" />}
            onClick={onOpen}
        />
    );

    return (
        <>
            {renderWithTooltip(IconButtonComp)}
            <CustomModal
                title="Create a recurring report"
                handleSend={handleCreate}
                isDisabled={
                    emails.length === 0 ||
                    !selectedFrequencyButton ||
                    !selectedRepeatButton ||
                    !selectedDay || 
                    recurringTemplateCount >= 10
                }
                isLoading={isLoading}
                isOpen={isOpen}
                onClose={handleClose}
                buttonName="Create"
            >
                <Flex direction="column" gap={3} position="relative">
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="700" fontSize="13px">Period and locations</Text>
                        {!disabled && (
                            <p style={{ fontWeight: 700, fontSize: '14px' }}>
                                {`${formatDate(startDate)} - ${formatDate(endDate)}`} - All locations
                            </p>
                        )}
                        <Text fontSize="11px" color="#484848">From next month you will receive monthly report for the respective month.</Text>
                    </Flex>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="700" fontSize="13px">Frequency</Text>
                        <Text fontSize="11px" color="#484848">Specify the time you would like to repeat your reports.</Text>
                        <Flex mt={2} gap={3}>
                            <Button
                                borderRadius="full"
                                bg={selectedFrequencyButton === "Monthly" ? "gray.700" : "#f0f0f0"}
                                color={selectedFrequencyButton === "Monthly" ? "white" : "black"}
                                px="16px"
                                py="8px"
                                h="34px"
                                onClick={() => setSelectedFrequencyButton("Monthly")}
                                _hover={{
                                    bg: selectedFrequencyButton === "Monthly" ? "gray.700" : "gray.200",
                                }}
                                fontWeight="700"
                                fontSize="sm"
                                fontFamily="Inter"
                            >
                                Monthly
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex direction="column" gap={1} position="relative">
                        <Text fontWeight="700" fontSize="13px">Send on</Text>
                        <Text fontSize="11px" color="#484848">Specify the time you would like to repeat your reports.</Text>
                        <Flex mt={2} gap={3}>
                            <Button
                                borderRadius="full"
                                bg={selectedRepeatButton?.startsWith("Each") ? "gray.700" : "#f0f0f0"}
                                color={selectedRepeatButton?.startsWith("Each") ? "white" : "black"}
                                px="16px"
                                py="8px"
                                h="34px"
                                onClick={() => {
                                    setSelectedRepeatButton("Each");
                                    setIsDatePickerOpen(true);
                                }}
                                _hover={{
                                    bg: selectedRepeatButton?.startsWith("Each") ? "gray.700" : "gray.200",
                                }}
                                fontWeight="700"
                                fontSize="sm"
                                fontFamily="Inter"
                            >
                                {selectedRepeatButton?.startsWith("Each") ? selectedRepeatButton : "Each"}
                            </Button>
                            <Button
                                borderRadius="full"
                                bg={selectedRepeatButton?.startsWith("On the") ? "gray.700" : "#f0f0f0"}
                                color={selectedRepeatButton?.startsWith("On the") ? "white" : "black"}
                                px="16px"
                                py="8px"
                                h="34px"
                                onClick={() => {
                                    setSelectedRepeatButton("On the");
                                    setIsDatePickerOpen(true);
                                }}
                                _hover={{
                                    bg: selectedRepeatButton?.startsWith("On the") ? "gray.700" : "gray.200",
                                }}
                                fontWeight="700"
                                fontSize="sm"
                                fontFamily="Inter"
                            >
                                {selectedRepeatButton?.startsWith("On the") ? selectedRepeatButton : "On the"}
                            </Button>
                        </Flex>
                        {isDatePickerOpen && customDatePicker({ handleSelect: handleDaySelect })}
                    </Flex>
                    <Flex direction="column" gap={1}>
                        <Text fontWeight="700" fontSize="13px">Send to</Text>
                        <Text fontSize="11px" color="#484848">Provide the email address(es) where you would like to receive your reports.</Text>
                        <EmailInput emails={emails} setEmails={setEmails} />
                    </Flex>
                </Flex>
            </CustomModal>
        </>
    );
}

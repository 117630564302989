import React from "react"
import { Route, Routes } from "react-router-dom"
import { ReportFilterContextProvider } from "../context/ReportFilterContext"
import NotFound from "../features/notFound/pages/NotFound"
import GenerateReport from "../features/reports/pages/GenerateReport"
import { ReportHistory } from "../features/reports/pages/ReportHistory"

export const ReportRoutes = () => {
    return (
    <ReportFilterContextProvider>
        <Routes>
          <Route path="history" element={<ReportHistory />} />
          <Route path="generate" element={<GenerateReport />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ReportFilterContextProvider>)
}
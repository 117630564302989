import { createIcon } from '@chakra-ui/icons'

const HumidityDownIcon = createIcon({
  displayName: 'HumidityDownIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <g clipPath="url(#clip0_5393_27087)">
        {/* <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.6425 4.53052C22.0671 4.1059 22.7556 4.1059 23.1802 4.53053L26.6627 8.01308L30.1452 4.53053C30.5698 4.1059 31.2583 4.1059 31.6829 4.53052C32.1075 4.95514 32.1075 5.64359 31.6829 6.06821L27.4315 10.3196C27.2276 10.5235 26.9511 10.6381 26.6627 10.6381C26.3743 10.6381 26.0978 10.5235 25.8938 10.3196L21.6425 6.06821C21.2179 5.64359 21.2179 4.95514 21.6425 4.53052ZM21.6425 12.3743C22.0671 11.9496 22.7556 11.9497 23.1802 12.3743L26.6627 15.8568L30.1452 12.3743C30.5698 11.9497 31.2583 11.9496 31.6829 12.3743C32.1075 12.7989 32.1075 13.4873 31.6829 13.912L27.4315 18.1634C27.2276 18.3673 26.9511 18.4818 26.6627 18.4818C26.3743 18.4818 26.0978 18.3673 25.8938 18.1634L21.6425 13.912C21.2179 13.4873 21.2179 12.7989 21.6425 12.3743ZM21.6425 21.0735C22.0671 20.6489 22.7556 20.6489 23.1802 21.0735L26.6627 24.5561L30.1452 21.0735C30.5698 20.6489 31.2583 20.6489 31.6829 21.0735C32.1075 21.4981 32.1075 22.1866 31.6829 22.6112L27.4315 26.8626C27.2276 27.0665 26.9511 27.1811 26.6627 27.1811C26.3743 27.1811 26.0978 27.0665 25.8938 26.8626L21.6425 22.6112C21.2179 22.1866 21.2179 21.4981 21.6425 21.0735Z"
          fill="#C6E3FD"
        /> */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00092 1C9.46744 1.00002 9.88968 1.26984 10.0765 1.68734L17.1148 17.4146C17.7472 18.7996 18.0477 20.3215 17.9938 21.8498C17.9399 23.3798 17.5323 24.8769 16.8016 26.2121C16.0708 27.5476 15.0365 28.6847 13.7815 29.5188C12.5268 30.3526 11.0911 30.857 9.59806 30.9814C9.38664 31.0001 9.17142 31 9.01847 31L8.99974 31C8.81071 31 8.62252 30.9946 8.43522 30.9838C8.42511 30.9833 8.41501 30.9825 8.40492 30.9817C6.91081 30.8577 5.47396 30.3532 4.21845 29.5188C2.96353 28.6848 1.9294 27.5478 1.19861 26.2125C0.467962 24.8775 0.0602413 23.3806 0.00618315 21.8508C-0.047821 20.3225 0.252506 18.8008 0.884637 17.4157L7.92525 1.68724C8.11213 1.26976 8.53439 0.99998 9.00092 1ZM9.00073 5.00893L3.03055 18.3461C2.54459 19.4096 2.3107 20.5857 2.35261 21.7717C2.39452 22.9577 2.71068 24.1111 3.26921 25.1316C3.8276 26.1519 4.60913 27.0049 5.5393 27.6231C6.46457 28.238 7.51101 28.6037 8.58869 28.6957C8.72443 28.7032 8.86143 28.707 8.99974 28.707C9.17748 28.707 9.2928 28.7061 9.38805 28.6976L9.39581 28.6969C10.4789 28.6071 11.5309 28.2409 12.4606 27.6231C13.3909 27.0048 14.1725 26.1517 14.7309 25.1313C15.2895 24.1106 15.6056 22.9571 15.6474 21.771C15.6892 20.5849 15.4552 19.4086 14.969 18.3451L14.9653 18.3369L9.00073 5.00893Z"
          fill="#157FEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_5393_27087">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})

export default HumidityDownIcon

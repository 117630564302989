import { createIcon } from '@chakra-ui/react'

const FilterOneIcon = createIcon({
  displayName: 'FilterOneIcon',
  viewBox: '0 0 24 24',
  path: (
    <g id="Leading-icon">
       <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" 
       stroke="#484848"
       strokeWidth="2" 
       strokeLinecap="round" 
       strokeLinejoin="round"
       fill="none"/>
    </g>
  ),
})

export default FilterOneIcon

import { IconButton, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react"
import { Bookmark } from "../../../../assets/icons/Bookmark";
import { useReportFilterContext } from "../../../../context/ReportFilterContext";
import { useCreateRecurringReportMutation, useDeleteBookmarkMutation } from "../../api/reportsApi";
import { ReportData } from "../../types";

type BookmarkReportProps = {
    disabled?: boolean;
    tooltip?: string;
} & ReportData.RecurringReportParams;

export default function BookmarkReport(props: BookmarkReportProps) {
    const {
        disabled,
        startDate,
        endDate,
        locations,
        deviceGroups,
        deviceTypes,
        energyType,
        reportType,
        measurementTypes,
        devices,
        totalDetailed,
        ibexPrice,
        carbonIntensity,
        reportName,
        tooltip,
        savedAs,
    } = props;

    const [createRecurringReport, { isLoading }] = useCreateRecurringReportMutation()
    const [isBookmarked, setIsBookmarked] = useState(false)
    const {bookmarkTemplateCount} = useReportFilterContext()
    const [bookmarkId, setBookmarkId] = useState(null)
    const [trigger, {isLoading: isDeleting}] = useDeleteBookmarkMutation()
    
    const handleUnselectBookmark = async () => {
        await trigger({ id: bookmarkId})
        setIsBookmarked(false)
    }


    
    const onClick = () => {
        if (!isBookmarked) {
            const locationsIds = locations?.map((x) => x.id.toString()).join(",")
            const groupsIds = deviceGroups?.map((x) => x.id.toString()).join(",")

            const recurringReportParams: ReportData.RecurringReportParams = {
                startDate: startDate,
                endDate: endDate,
                locations: locationsIds,
                deviceGroups: groupsIds,
                deviceTypes: deviceTypes,
                energyType: energyType,
                reportType: reportType,
                measurementTypes: measurementTypes,
                devices: devices,
                totalDetailed: totalDetailed,
                ibexPrice: ibexPrice,
                carbonIntensity: carbonIntensity,
                reportName: reportName,
                filters_type: "bookmark",
                savedAs: savedAs,
            }
            createRecurringReport(recurringReportParams).then((result: any) => setBookmarkId(result?.data.report_id))
            setIsBookmarked(true)
        } else {
            handleUnselectBookmark()
        }
    }
    const tooltipLabel = "You have reached the limit of saved samples. To save a new one, please remove an old sample."

    const renderWithTooltip = (children: JSX.Element) => (
        <Tooltip
            w="full"
            label={tooltipLabel}
            placement="top"
            hasArrow={true}
            shouldWrapChildren
            isDisabled={bookmarkTemplateCount  < 20}
        >
            {children}
        </Tooltip>
    );

    const IconButtonComp = (
        <IconButton
            size="sm"
            disabled={(bookmarkTemplateCount >= 20) || isDeleting }
            background="none"
            aria-label="bookmark"
            _focus={{ boxShadow: 'none' }}
            icon={<Bookmark boxSize="24px" color={isBookmarked ? "#157fee" : "white"} />}
            onClick={onClick}
            isLoading={isLoading}
        />
    );

    return renderWithTooltip(IconButtonComp)
}
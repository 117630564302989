import { Grid, GridItem } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomTabs from '../../../app/components/CustomTabs/CustomTabs'
import Header from '../../../app/components/Header'
import { DeviceGroupConfiguration } from './DeviceGroupConfiguration'
import { RegisteredNewDevice } from './RegisteredNewDevice'

export const DeviceConfiguration = () => {
  const [index, setIndex] = useState(0)

  const componentMap = {
    0: RegisteredNewDevice,
    1: DeviceGroupConfiguration,
  }

  const Component = componentMap[index]

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={5}>
      <GridItem>
        <Header title="Device Configuration" />
      </GridItem>
      <CustomTabs
        tabs={[{ label: 'Register Device' }, { label: 'Group configuration' }]}
        activeIndex={index}
        setActiveIndex={setIndex}
      />
      <Component />
    </Grid>
  )
}

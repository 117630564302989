import { BarIcon } from '../../assets/icons/Bar'
import { HomeIcon } from '../../assets/icons/Home'
import { LocationsIcon } from '../../assets/icons/Locations'
import { AutomationIcon } from '../../assets/icons/Automation'
import { CustomLink } from './CustomLink'
import { ReportsIcon } from '../../assets/icons/Report'
import { useAlarmsCountContext } from '../../context/AlarmsCountContext'
import BellIcon from '../../assets/icons/Alarm'
import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Badge from './Badge/Badge'
import { CpuIcon } from '../../assets/icons/CpuIcon'

const NavItems = () => {
  const alarmContext = useAlarmsCountContext()
  const upperMenuItems = [
    {
      Icon: HomeIcon,
      text: 'Overview',
      to: '/dashboard',
      iconWidth: '6',
      iconHeight: '6',
    },
    {
      Icon: BarIcon,
      text: 'Analysis',
      to: '/analysis',
      iconWidth: '6',
      iconHeight: '5',
    },
    // {
    //   Icon: LocationsIcon,
    //   text: 'Locations',
    //   to: '/locations',
    //   iconWidth: '7',
    //   iconHeight: '7',
    // },
    {
      Icon: AutomationIcon,
      text: 'Automations',
      to: '/automations',
      iconWidth: '9',
      iconHeight: '9',
    },
    {
      Icon: ReportsIcon,
      text: 'Reports',
      to: '/reports/generate',
      iconWidth: '8',
      iconHeight: '8',
    },
    {
      Icon: BellIcon,
      text: 'Alarms',
      to: '/alarms',
      iconWidth: '8',
      iconHeight: '8',
      TextWrapper: ({ children }) => (
        <Flex justifyContent="space-between" w="full" align="center">
          {children}
          {alarmContext.alarmsCount > 0 ? (
            <Badge bg="icon.strong">
              <Text color="white" fontSize="12px" fontWeight="700" p="2px">
                {alarmContext.alarmsCount}
              </Text>
            </Badge>
          ) : null}
        </Flex>
      ),
    },
    // {
    //   Icon: NotificationsIcon,
    //   text: "Notifications",
    //   to: "/notifications",
    //   iconWidth: "6",
    //   iconHeight: "4",
    // },
    {
      Icon: CpuIcon,
      text: "Device Configuration",
      to: "/deviceConfiguration",
      iconWidth: "6",
      iconHeight: "6",
    }
  ]

  return (
    <>
      {upperMenuItems.map(
        ({ Icon, text, to, iconWidth = 5, iconHeight = 6, TextWrapper }, idx: number) => (
          <CustomLink
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            Icon={Icon}
            to={to}
            key={idx}
            TextWrapper={TextWrapper}
          >
            {text}
          </CustomLink>
        ),
      )}
    </>
  )
}

export default NavItems

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useGetUnregisteredDeviceListQuery, useHardDeleteMutation } from '../api/unregisteredDeviceApi'
import { RegisteredNewDeviceForm } from '../components/RegisteredNewDiveceForm'

export const RegisteredNewDevice = () => {
  const [deviceId, setDeviceId] = useState<number>()
  const { data: unregisteredDevice, isLoading: devicesLoading } = useGetUnregisteredDeviceListQuery()
  const [deleteDevice] = useHardDeleteMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDelete = async (id: number) => {
    await deleteDevice({ id })
    console.log("Deleted", id)
    onClose()
  }

  const handleOpen = (id: number) => {
    onOpen()
    setDeviceId(id)
  }

  const handleClose = () => {
    onClose()
    setDeviceId(null)
  }

  return (
    <Skeleton isLoaded={!devicesLoading}>
      <TableContainer>
        <Table variant="unstyled" bgColor="white">
          <Thead>
            <Tr>
              <Th>Device id</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {unregisteredDevice?.length > 0 ? (
              unregisteredDevice?.map((x, index) => {
                return (
                  <Tr key={index}>
                    <Td>{x.mac_id}</Td>
                    <Td>
                      <Flex gap="10px">
                        <RegisteredNewDeviceForm physicalId={x.mac_id} />
                        <Button
                          mt={8}
                          onClick={() => handleOpen(x.id)}
                        >
                          Delete Device
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td>No new devices</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this device?
            </ModalBody>

            <ModalFooter>
              <Flex gap="10px">
                <Button onClick={handleClose}>
                  Close
                </Button>
                <Button colorScheme="button.primary" onClick={() => handleDelete(deviceId)}>Yes</Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </TableContainer>
    </Skeleton>
  )
}

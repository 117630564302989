import { createIcon } from '@chakra-ui/icon'

export const CpuIcon = createIcon({
  displayName: 'CpuIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15 9H9V15H15V9Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9 1V4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15 1V4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9 20V23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15 20V23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20 9H23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20 14H23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1 9H4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1 14H4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  ),
})

{
  /* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2159_2662)">
<path d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z" stroke="#484848" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M15 9H9V15H15V9Z" stroke="#484848" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
<path d="M9 1V4" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 1V4" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 20V23" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 20V23" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 9H23" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 14H23" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 9H4" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 14H4" stroke="#484848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2159_2662">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg> */
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Container,
  Grid,
  FormControl,
  Input,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react'
import { OptionBase } from 'chakra-react-select'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { EditTwoIcon } from '../../../assets/icons/EditTwoIcon'
import { useUpdateDeviceGroupMutation } from '../api/deviceGroupConfigurationApi'
import { DeviceGroupListType, DeviceResult } from '../api/types'
import { ControlledSelect } from './ControlledSelect'

interface DevicesType extends OptionBase {
  label: string
  value: number
}

interface FormValue {
  devices: DevicesType[]
  deviceGroupName: string
}
type Props = {
  deviceGroup: DeviceGroupListType,
  devicesWhitOutGroup: DeviceResult[]
}

export const EditDeviceGroupModal = ({ deviceGroup, devicesWhitOutGroup }: Props) => {
  const {
    reset,
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValue>()

  const [updateDeviceGroup] = useUpdateDeviceGroupMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isOpen) {
      setValue(
        'devices',
        deviceGroup.devices.map((x) => ({ value: x.id, label: x.title }))
      );
      setValue('deviceGroupName', deviceGroup.name);
    }
  }, [deviceGroup, isOpen, setValue]);


  const submit: SubmitHandler<FormValue> = async (data) => {
    const body = {
      name: data.deviceGroupName,
      devices: data.devices.map((x) => x.value),
      id: deviceGroup.id,
    }
    await updateDeviceGroup(body).catch((error) => console.log(error))

    onClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      <IconButton
        background="none"
        aria-label="edit device group"
        size="md"
        _focus={{ boxShadow: 'none' }}
        icon={<EditTwoIcon />}
        onClick={onOpen}
      />

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <Container as="form" mb={12} onSubmit={handleSubmit(submit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Device Group</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid templateColumns="repeat(1, 1fr)" gap={5}>
                <FormControl isInvalid={!!errors.deviceGroupName}>
                  <Input
                    name="deviceGroupName"
                    variant="outline"
                    placeholder="Device group name"
                    {...register('deviceGroupName', {
                      required: 'Device group name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.deviceGroupName && errors.deviceGroupName.message}
                  </FormErrorMessage>
                </FormControl>
                <ControlledSelect
                  control={control}
                  isMulti
                  name="devices"
                  id="devices"
                  options={
                    [...devicesWhitOutGroup, ...deviceGroup.devices]?.map((x) => {
                      return { label: x.title, value: x.id }
                    }) || []
                  }
                  placeholder="Devices"
                  label="Devices"
                  rules={{ required: 'Please enter at least one device.' }}
                />
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={handleClose}>Cancel</Button>
              <Button type="submit" colorScheme="blue" >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Container>
      </Modal>
    </>
  )
}

import React from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

const PercentageChange = ({ percentageChange, iconBoxSize = 5, percentageFontSize = 15 }) => {
    const colorChange = percentageChange > 0 ? "#00CC66" : "#ff402b"
    return (
        <>
            {percentageChange !== 0 && (
                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                    {percentageChange > 0
                        ? <TriangleDownIcon className="arrowIconClass" boxSize={iconBoxSize} color={colorChange} />
                        : <TriangleUpIcon className="arrowIconClass" boxSize={5} color={colorChange} />
                    }
                    <div style={{ color: colorChange, fontWeight: 700, fontSize: percentageFontSize }}>{percentageChange}%</div>
                </div>
            )}
        </>
    )
};

export default PercentageChange;

import React from 'react'
import {
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuDivider,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react'
import OverflowIcon from '../../../../assets/icons/Overflow'
import { useDeleteBookmarkMutation } from '../../api/reportsApi'
import { CloseIcon } from '../../../../assets/icons/Close'
import { useReportFilterContext } from '../../../../context/ReportFilterContext'
import { ReportHistoryData } from '../../types'
import { useNavigate } from 'react-router-dom'
import FilterOneIcon from '../../../../assets/icons/FilterOneIcon'
import { RescheduleRecurringReportModal } from './ReportTemplateHistory/RescheduleRecurringReportModal'
import { RecurringReport } from '../../../../assets/icons/RecurringReport'

type Props = {
  record: ReportHistoryData.ReportHistory
}

export default function OverflowButton({ record }: Props) {
  const [trigger, { isLoading: isDeleting }] = useDeleteBookmarkMutation()
  const { setReportFilters } = useReportFilterContext()
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemoveClick = async () => {
    await trigger({ id: record.id })
  }

  const handleApplyFilterClick = () => {
    setReportFilters(record.filters)
    navigate('/reports/generate')
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<OverflowIcon boxSize="24px" />}
        variant="outline"
        border="none"
        disabled={isDeleting}
      />
      <MenuList p="0" width="290px">
        <MenuDivider m="0" />
         {record.is_recurring &&  
        <MenuItem p="16px" onClick={onOpen} icon={<RecurringReport boxSize="20px" />}>
         <Text>Reschedule recurring report</Text>
          <RescheduleRecurringReportModal
            startDate={new Date(record.filters.from_date)}
            endDate={new Date(record.filters.to_date)}
            disabled={false}
            isOpen={isOpen}
            modalTitle={"Edit recurring report"}
            sendOn={record.filters.send_on}
            sendTo={record.filters.send_to}
            onClose={onClose}
            record={record}
          />
        </MenuItem>
         }
        <MenuDivider m="0" />
        <MenuItem
          icon={<FilterOneIcon boxSize="20px" />}
          onClick={handleApplyFilterClick}
          p="16px"
        >
          <Text>Apply filters</Text>
        </MenuItem>
        <MenuDivider m="0" />
        <MenuItem
          icon={<CloseIcon boxSize="20px" />}
          p="16px"
          onClick={handleRemoveClick}
          isDisabled={isDeleting}
        >
          <Text>Remove</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

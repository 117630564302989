import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { LocationChart } from '../../features/dashboard/types'
import { addCommasToNumber } from '../../utils/helpers'

type TemperatureChartProps = {
  data: LocationChart.Data[]
  lineProps: { dataKey: string; type: string }
  period: string
  title: string
  measurementUnit: string
  icon?: JSX.Element
}

const calculateAverage = (data, key) => {
  const total = data.reduce((sum, entry) => sum + entry[key], 0)
  return total / data.length
}

export const AverageLineChart = ({
  data,
  lineProps,
  period,
  title,
  measurementUnit,
  icon,
}: TemperatureChartProps) => {
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })
  const [mouseOverAverage, setMouseOverAverage] = useState<boolean>()
  const average = calculateAverage(data, lineProps.dataKey)

  const viewData = useMemo(
    () =>
      data.map((item, _index) => {
        return {
          ...item,
          time:
            period === 'day'
              ? format(new Date(item.time), 'hh:mm a')
              : format(new Date(item.time), 'MMM d'),

          month: format(new Date(item.time), 'MMM d'),
          hours: format(new Date(item.time), 'hh:mm a'),
        }
      }),
    [data, period],
  )

  const toolTipPosition = React.useMemo<TooltipProps<number, string>['position']>(() => {
    if (mouseCoords) {
      const OffsetY = mouseOverAverage ? 125 : 155

      const tooltipWidth = document.getElementById('custom-tooptip')?.offsetWidth

      return {
        x: mouseCoords.x - tooltipWidth / 2,
        y: mouseCoords.y - OffsetY,
      }
    }
    return undefined
  }, [mouseCoords])

  const CustomAverageTooltip = useCallback(
    ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <Box id="custom-tooptip">
            <Box background="white" boxShadow="xl" rounded="2xl">
              <Flex direction="column" gap={2} p={4}>
                {!mouseOverAverage && (
                  <Text fontSize="14px" color="text.secondary">
                    {title}
                  </Text>
                )}
                <Flex alignItems="center">
                  {mouseOverAverage && (
                    <>
                      {icon}
                      <Text fontSize="16px" color="#484848">
                        &nbsp;Average&nbsp;
                      </Text>
                    </>
                  )}
                  <Text fontSize="20px" color="#484848" fontWeight={700}>
                    {mouseOverAverage
                      ? addCommasToNumber(average)
                      : addCommasToNumber(payload[0].value)}
                  </Text>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {measurementUnit}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {(period === 'day' || period === 'week')
                      ? `${payload[0].payload.month} ${payload[0].payload.hours}`
                      : payload[0].payload.time}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Flex justifyContent="space-around">
              <Box
                mt="-1"
                height="0"
                width="0"
                borderLeft="15px solid transparent"
                borderRight="15px solid transparent"
                borderTop="20px solid white"
              />
            </Flex>
          </Box>
        )
      }
      return null
    },
    [mouseOverAverage],
  )

  const handleMouseMove = useCallback(
    (e) => {
      if (e && e.chartX && e.chartY) {
        setMouseCoords({ x: e.chartX, y: e.chartY })
      }
    },
    [mouseOverAverage],
  )

  return (
    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
      <Box p={2}>
        <Text fontSize="16px" color="#484848" fontWeight={700}>
          {title}
        </Text>
      </Box>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          data={viewData}
          margin={{ top: 0, left: 0, right: 25, bottom: 0 }}
          onMouseMove={handleMouseMove}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <Line
            type="monotone"
            dataKey={lineProps.dataKey}
            stroke="#181716"
            strokeWidth="3"
          />
          <XAxis
            dataKey="time"
            tickLine={false}
            interval={'preserveStartEnd'}
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: 'black' }}
          />
          <YAxis
            tickLine={false}
            tickFormatter={(value) => `${value} ${measurementUnit}`}
            width={40}
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: 'black' }}
            domain={[0, (dataMax) => Math.ceil(dataMax + dataMax / 10)]}
          />
          <ReferenceLine
            id="average-line"
            y={average}
            stroke="black"
            strokeWidth={2}
            strokeDasharray="10 10"
            onMouseOver={() => setMouseOverAverage(true)}
            onMouseOut={() => setMouseOverAverage(false)}
          />
          <Tooltip
            content={<CustomAverageTooltip />}
            position={toolTipPosition}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

import React, { useState } from 'react';
import {
  TableContainer,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { DeviceGroupListType, DeviceResult } from '../api/types';
import { DeleteDeviceGroupModal } from './DeleteDeviceGroupModal';
import { EditDeviceGroupModal } from './EditDeviceGroupModal';
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

type Props = {
  groupDeviceList: DeviceGroupListType[];
  ungroupedDevices: DeviceResult[];
};

export const DeviceGroupTable: React.FC<Props> = ({ groupDeviceList, ungroupedDevices }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const toggleRow = (index) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null);
    } else {
      setExpandedRowIndex(index);
    }
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width="50px" />
            <Th>Group name</Th>
            <Th style={{ textAlign: 'end' }}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {groupDeviceList?.map((group, index) => (
            <>
              <Tr key={group.id || index}>
                {group.devices?.length > 0 ? <Td>
                  <IconButton
                    size="sm"
                    icon={
                      expandedRowIndex === index ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                    onClick={() => toggleRow(index)}
                    variant="ghost"
                    aria-label="Expand row"
                  />
                </Td> : <Td />}
                <Td>{group.name}</Td>
                <Td style={{ textAlign: 'end' }}>
                  <EditDeviceGroupModal deviceGroup={group} devicesWhitOutGroup={ungroupedDevices} />
                  <DeleteDeviceGroupModal groupId={group.id} />
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={3} p={0}>
                  <Collapse in={expandedRowIndex === index}>
                    <Box p={4} bg="gray.100">
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Device name</Th>
                            <Th>Device type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {group?.devices?.map((device, index) => (
                            <>
                              <Tr key={device.id || index}>
                                <Td>{device.title}</Td>
                                <Td>{device.device_type != null ? device.device_type.name : "No device types available"}</Td>
                              </Tr>
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </Collapse>
                </Td>
              </Tr>
            </>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  useMediaQuery,
  Wrap,
} from '@chakra-ui/react'
import { Card } from './components/Card'
import addDevice from '../../assets/locations/addDeviceIcon.svg'
import locationIcon from '../../assets/locations/locationIcon.svg'
import { Icon } from '../../utils/Icon'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react'
import { Location } from '../../app/services/api/types'
import { useGetLocationsInfoQuery } from './api/locationsApi'
import Header from '../../app/components/Header'

const LocationsList = ({
  locations,
  refetch,
}: {
  locations: Location[]
  refetch: any
}) => {
  return (
    <>
      {locations.map((item: Location) => (
        <Card
          property={{
            location: item.title,
            imageUrl:
              item.image ||
              (item.description ? `/locations/${item.description}.png` : false) ||
              'https://bit.ly/2Z4KKcF',
            imageAlt: '',
            id: item.id,
          }}
          key={item.title}
          refetchLocations={refetch}
        />
      ))}
    </>
  )
}

const LocationsPage = () => {
  const navigate = useNavigate()
  const [locations, setLocations] = useState<Location[]>([])
  const [search, setSearch] = useState('')

  const { data: locationsData, isFetching, refetch } = useGetLocationsInfoQuery()

  useEffect(() => {
    if (locationsData) {
      setLocations(locationsData.results)
    }
  }, [locationsData])

  const handleSearch = (e: any) => {
    const searchValue = e.target.value
    setSearch(searchValue)

    if (!locationsData?.results) {
      return
    }
    const filteredLocations = locationsData.results.filter((loc) => {
      return loc.title.toLowerCase().includes(searchValue.toLowerCase().trim())
    })

    setLocations(filteredLocations)
  }

  const [isSmallerThan700] = useMediaQuery('(max-width: 700px)')

  return (
    <>
      <Header></Header>
      <Box boxShadow="lg" rounded="lg" borderWidth={1} p={6} mt={5}>
        <HStack flexDir={isSmallerThan700 ? 'column' : 'row'}>
          <Box
            w={isSmallerThan700 ? '100%' : '50%'}
            paddingRight={isSmallerThan700 ? '0' : '15px'}
            mb={isSmallerThan700 ? '20px' : '0'}
          >
            <InputGroup w="100%">
              <Input
                placeholder="Seach location"
                value={search}
                onChange={handleSearch}
              />
              <InputRightElement>
                <SearchIcon color="gray" />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box
            w={isSmallerThan700 ? '100%' : '50%'}
            marginInlineStart={isSmallerThan700 ? 0 : '0.5rem'}
          >
            <Button
              leftIcon={<Icon src={locationIcon} />}
              colorScheme="button.primary"
              variant="outline"
              w={'calc(50% - 10px)'}
              onClick={() => {
                navigate('/locations/add-location', {
                  state: {
                    path: 'locations',
                  },
                })
              }}
            >
              Add Location
            </Button>
            <Button
              leftIcon={<Icon src={addDevice} />}
              colorScheme="button.primary"
              variant="outline"
              w={'calc(50% - 10px)'}
              marginLeft="20px"
              onClick={() => {
                navigate('/locations/add-device', {
                  state: {
                    path: 'locations',
                  },
                })
              }}
            >
              Add device
            </Button>
          </Box>
        </HStack>

        <Wrap spacing={6} mt={6}>
          {!locations || isFetching ? (
            Array.from(Array(9)).map((_, i) => (
              <Skeleton key={i}>
                <Card />
              </Skeleton>
            ))
          ) : (
            <LocationsList locations={locations} refetch={refetch} />
          )}
        </Wrap>
      </Box>
    </>
  )
}

export default LocationsPage

import { Center, Spinner } from '@chakra-ui/react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import Cookies from 'js-cookie'
import { useLazyGetProfileQuery, useLoginMutation } from '../features/auth/api/authApi'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

type Props = {
  redirectTo: string
  children?: JSX.Element
}

export const PrivateRoute = ({ children, redirectTo }: Props): JSX.Element => {
  const { user, setUser } = useUser();
  const [getProfile] = useLazyGetProfileQuery();
  const [login, { isError }] = useLoginMutation();
  const location = useLocation();
  const navigate = useNavigate();

  const methodiaAiotCookie = Cookies.get('aiot_methodia_user');
  const token = window.localStorage.getItem('aiot_t');

  // Use to prevent duplicate requests
  const hasAutoLoggedIn = useRef(false)

  useEffect(() => {
    if (!token && methodiaAiotCookie && !hasAutoLoggedIn.current) {
      hasAutoLoggedIn.current = true;
      // Auto-login flow
      login({ email: methodiaAiotCookie, password: 'placeholder' })
        .unwrap()
        .then(({ token }) => {
          window.localStorage.setItem('aiot_t', token);
          getProfile()
            .unwrap()
            .then((user) => {
              setUser(user);
            })
        })
        .catch((error) => {
          toast.error(
            error && error.data
              ? error.data.non_field_errors[0]
              : 'Something went wrong.',
            {
              style: { padding: '10px 20px' },
              autoClose: 3000,
            },
          )

          navigate(redirectTo, { replace: true, state: { from: location } })
        })
    }
  }, [
    token,
    methodiaAiotCookie,
    login,
    getProfile,
    setUser,
    navigate,
    location,
    redirectTo,
  ])

  if (methodiaAiotCookie && !user && !isError) {
    return (
      <Center w="full" h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  if (token && user) {
    return children ? <>{children}</> : <Outlet />
  }

  if (!token || isError) {
    return <Navigate replace to={redirectTo} state={{ from: location }} />
  }

  return (
    <Center w="full" h="100vh">
      <Spinner size="xl" />
    </Center>
  )
}

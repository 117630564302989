import { createContext, PropsWithChildren, useContext, useState } from 'react'
import {ReportHistoryData} from '../features/reports/types/index'

const ReportFilterContext = createContext<{
  reportFilters: ReportHistoryData.ReportFilters
  bookmarkTemplateCount: number,
  recurringTemplateCount: number
  setReportFilters: (reportFilters: ReportHistoryData.ReportFilters) => void,
  setBookmarkTemplateCount: (bookmarkTemplateCount: number) => void
  setRecurringTemplateCount: (recurringTemplateCount: number) => void
}>({
    reportFilters: {} as ReportHistoryData.ReportFilters,
    bookmarkTemplateCount: 0,
    recurringTemplateCount: 0,
    setReportFilters: () => null,
    setBookmarkTemplateCount: () => null,
    setRecurringTemplateCount: () => null,
})

export const useReportFilterContext = () => {
  return useContext(ReportFilterContext)
}

export const ReportFilterContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [reportFilters, setReportFilters] = useState<ReportHistoryData.ReportFilters>()
  const [bookmarkTemplateCount, setBookmarkTemplateCount] = useState<number>()
  const [recurringTemplateCount, setRecurringTemplateCount] = useState<number>()
  return (
    <ReportFilterContext.Provider value={{ reportFilters, bookmarkTemplateCount,recurringTemplateCount, setReportFilters, setBookmarkTemplateCount, setRecurringTemplateCount}}>
      {children}
    </ReportFilterContext.Provider>
  )
}

export default ReportFilterContext
import { Tab, TabList, Tabs } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import 'simplebar/dist/simplebar.min.css'
import { DASHBOARDPARAMS, LOCATIONPARAMS } from '../consts'
import { useQueryParams } from '../hooks/useQueryParams'
import { Title, DashboardOverviewLocation } from '../types'
import { getLocationIndex } from '../../../utils/helpers'

export type TabStripData = Title & DashboardOverviewLocation
type Props = {
  data: TabStripData[]
  clearNavigationPath?: string
  handleNavigationPath?: string
}

export const LocationTabStrip: React.FC<Props> = ({
  data,
  clearNavigationPath,
  handleNavigationPath,
}) => {
  const [tabIndex, setTabIndex] = useState(0)

  const { locationId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  const [queryParams] = useQueryParams(DASHBOARDPARAMS)
  const dataLength = data.length

  useEffect(() => {
    const locationIndex =
      dataLength === 1
        ? 0
        : getLocationIndex(data, locationId || searchParams.get('location'))

    setTabIndex(locationIndex)
  }, [locationId, data, dataLength, searchParams])

  const clearLocation: () => void = () => {
    if (clearNavigationPath) {
      navigate(`/${clearNavigationPath}?${new URLSearchParams(queryParams).toString()}`)
      return
    }

    searchParams.delete(LOCATIONPARAMS[0])
    setSearchParams(searchParams)
  }

  const initialTabItems =
    dataLength > 1
      ? [
          <Tab
            key="all"
            onClick={clearLocation}
            fontWeight="400"
            padding="0 24px"
            height="36px"
            _selected={{
              fontSize: '14px',
              fontWeight: '700',
              bg: '#F7F9FD',
              outline: 'none',
              shadow: 'none',
            }}
          >
            All
          </Tab>,
        ]
      : []
  const dynamicTabItems = data.map((tabItem, idx) => (
    <Tab
      key={idx}
      fontSize="sm"
      onClick={() => handleNavigation(tabItem)}
      fontWeight="400"
      padding="0 24px"
      height="36px"
      _selected={{
        fontSize: '14px',
        fontWeight: '700',
        bg: '#F7F9FD',
        outline: 'none',
        shadow: 'none',
      }}
    >
      {tabItem.title}
    </Tab>
  ))

  const tabItems = [...initialTabItems, ...dynamicTabItems]

  const navigate = useNavigate()
  const handleNavigation: (item: TabStripData) => void = (tabItem) => {
    if (handleNavigationPath) {
      navigate(
        `/${handleNavigationPath}/${tabItem.id.toString()}?${new URLSearchParams(
          queryParams,
        ).toString()}`,
      )
      return
    }

    setSearchParams({
      ...queryParams,
      ...{ location: tabItem.id.toString() },
    })
  }

  return (
    <Tabs
      variant="soft-rounded"
      w="fit-content"
      bg="white"
      rounded="3xl"
      colorScheme="gray"
      index={tabIndex}
      padding="4px"
      onChange={handleTabsChange}
      marginBottom="16px"

      // overflowX="auto"
    >
      <TabList padding="4px">{tabItems}</TabList>
    </Tabs>
  )
}

import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  useGetDeviceTypesQuery,
  useGetLocationsInfoQuery,
  useGetEnergyTypesQuery,
} from '../../locations/api/locationsApi'
import { DataSource, DeviceCategory, RegisterDeviceBody, SelectOption } from '../api/types'
import { usePostRegisteredDeviceMutation } from '../api/unregisteredDeviceApi'
import { DATA_SOURCE, DEVICE_CATEGORY, REQUIRED } from '../consts'
import { generateSelectOptions } from '../utils'
import { ControlledSelect } from './ControlledSelect'

interface FormValue {
  deviceName: string
  locationId: SelectOption
  deviceType: SelectOption
  energyTypeId:SelectOption
  dataSource: DataSource
  phaseCount: number
  deviceCategory: DeviceCategory
}

type Props = {
  physicalId: string
}

export const RegisteredNewDeviceForm = ({ physicalId }: Props) => {
  const {
    reset,
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FormValue>({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: locationsData, isLoading } = useGetLocationsInfoQuery()
  const { data: energyTypes } = useGetEnergyTypesQuery({})

  const { data: deviceTypes, isLoading: isDeviceTypesLoading } = useGetDeviceTypesQuery({ groupIds: [] });

  const [postRegisteredDevice, { isLoading: isDeviceRegister }] = usePostRegisteredDeviceMutation();

  const energyTypesSelect = useMemo(
    () =>
      energyTypes?.results.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [energyTypes],
  )

  const submit: SubmitHandler<FormValue> = async (data) => {
    const body: RegisterDeviceBody = {
      title: data.deviceName,
      location_id: data.locationId.value as number,
      device_type_id: data.deviceType.value as number,
      device_classification_id: data.energyTypeId.value as number,
      data_source: data.dataSource.value,
      phase_count: data.phaseCount,
      physical_id: physicalId,
      category: data.deviceCategory.value
    }
    await postRegisteredDevice(body)
    reset()
    onClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      <Button
        mt={8}
        alignSelf="flex-start"
        colorScheme="button.primary"
        onClick={onOpen}
      // disabled={!locationId}
      >
        Register Device
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <Container as="form" mb={12} onSubmit={handleSubmit(submit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Register Device {physicalId}</ModalHeader>
            <ModalCloseButton disabled={isDeviceRegister} />
            <ModalBody pb={6}>
              <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                <FormControl isInvalid={!!errors.deviceName}>
                  <Input
                    name="deviceName"
                    variant="outline"
                    placeholder="Device name *"
                    {...register('deviceName', {
                      required: 'Device name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.deviceName && errors.deviceName.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <ControlledSelect
                    control={control}
                    disabled={!isLoading}
                    name="locationId"
                    id="locationId"
                    options={generateSelectOptions(locationsData?.results)}
                    placeholder="Select location *"
                    label="Select location"
                    rules={{ required: REQUIRED }}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <ControlledSelect
                    control={control}
                    disabled={!isLoading}
                    name="energyTypeId"
                    id="energyTypeId"
                    options={energyTypesSelect}
                    placeholder="Select energy type *"
                    label="Select energy type"
                    rules={{ required: REQUIRED }}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <ControlledSelect
                    control={control}
                    disabled={!isDeviceTypesLoading}
                    name="deviceType"
                    id="deviceType"
                    options={generateSelectOptions(deviceTypes?.results)}
                    placeholder="Select device type *"
                    label="Select device type"
                    rules={{ required: REQUIRED }}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <ControlledSelect
                    control={control}
                    name="dataSource"
                    id="dataSource"
                    options={DATA_SOURCE}
                    placeholder="Select data source *"
                    label="Select Data source"
                    rules={{ required: REQUIRED }}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <ControlledSelect
                    control={control}
                    name="deviceCategory"
                    id="deviceCategory"
                    options={DEVICE_CATEGORY}
                    placeholder="Select device category *"
                    label="Select device category"
                    rules={{ required: REQUIRED }}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.deviceName}>
                  <NumberInput inputMode={'numeric'} min={0} max={3}>
                    <NumberInputField
                      placeholder="Phase count *"
                      {...register('phaseCount', {
                        required: REQUIRED,
                        min: 0,
                        max: 3,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormErrorMessage>
                    {errors.phaseCount && errors.phaseCount.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={isDeviceRegister} disabled={isDeviceRegister} mr={3} onClick={handleClose}>
                Cancel
              </Button>
              <Button isLoading={isDeviceRegister} disabled={isDeviceRegister} type="submit" colorScheme="blue">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Container>
      </Modal>
    </>
  )
}

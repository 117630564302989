import { useRef, useState } from 'react'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useOutsideClick,
} from '@chakra-ui/react'
import CalendarIcon from '../../../assets/icons/CalendarIcon'
import DateRangePicker from './DateRangePicker'
import { format } from 'date-fns'
import { ErrorMessage } from '@hookform/error-message'
import { FormInputProps } from './types'

const DateRangeInputs: React.FC<FormInputProps> = ({
  selectedStartName,
  selectedEndName,
  register,
  watch,
  setValue,
  startDate,
  endDate,
  errors,
  isRequired = true,
  highlightOnError = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref,
    handler: () => setIsOpen(false),
  })

  const setSelectedStart = (date: Date | null) =>
    setValue(selectedStartName as any, date as any)
  const setSelectedEnd = (date: Date | null) =>
    setValue(selectedEndName as any, date as any)

  const renderMessage = (message: string) => (
    <Text color="#CF202F" fontSize="11px">
      {message}
    </Text>
  )

  const hasError = (inputName: string) =>
    Object.keys(errors).some((key) => key === inputName)

  return (
    <Box zIndex="11" w="full">
      <Flex justifyContent="space-between" alignItems="center">
        <InputGroup>
          <Input
            autoComplete="off"
            isInvalid={hasError(selectedStartName)}
            placeholder="From"
            background="white"
            errorBorderColor="#CF202F"
            borderColor={highlightOnError && !startDate && '#CF202F'}
            cursor="pointer"
            fontSize="16px"
            readOnly
            {...register(
              selectedStartName as any,
              isRequired
                ? {
                    required: 'Please select range',
                  }
                : {},
            )}
            value={startDate ? format(startDate, 'dd.MM.yyyy') : ''}
            onClick={() => setIsOpen(true)}
          />

          <InputRightElement pointerEvents="none">
            <CalendarIcon color="gray.400" />
          </InputRightElement>
        </InputGroup>
        <Box m="3" w="8px" border="1px" borderColor="#9B9B9B" />
        <InputGroup>
          <Input
            autoComplete="off"
            background="white"
            isInvalid={hasError(selectedEndName)}
            errorBorderColor="#CF202F"
            borderColor={highlightOnError && !startDate && '#CF202F'}
            placeholder="To"
            value={endDate ? format(endDate, 'dd.MM.yyyy') : ''}
            cursor="pointer"
            readOnly
            onClick={() => setIsOpen(true)}
            {...register(
              selectedEndName as any,
              isRequired
                ? {
                    required: 'Please select range',
                  }
                : {},
            )}
          />
          <InputRightElement pointerEvents="none">
            <CalendarIcon color="gray.400" />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <Flex>
        {errors[selectedStartName] && errors[selectedEndName] ? (
          <ErrorMessage
            errors={errors}
            name={selectedStartName as any}
            render={({ message }) => renderMessage(message)}
          />
        ) : (
          <>
            <ErrorMessage
              errors={errors}
              name={selectedStartName as any}
              render={({ message }) => renderMessage(message)}
            />
            {errors[selectedEndName] && (
              <ErrorMessage
                render={({ message }) => renderMessage(message)}
                errors={errors}
                name={selectedEndName as any}
              />
            )}
          </>
        )}
      </Flex>
      {isOpen ? (
        <Box position="absolute" ref={ref}>
          <DateRangePicker
            selectedStart={startDate}
            setIsOpen={setIsOpen}
            selectedEnd={endDate}
            setSelectedStart={setSelectedStart}
            setSelectedEnd={setSelectedEnd}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default DateRangeInputs

import { Box, Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../../utils/helpers";
import { useUpdateRecurringReportMutation } from "../../../api/reportsApi";
import { ReportHistoryData } from "../../../types";
import CustomModal from "../CustomModal";
import EmailInput from "../EmailInput";

interface Props {
    disabled: boolean;
    startDate: any;
    endDate: any;
    isOpen: boolean;
    modalTitle: string;
    sendOn: string;
    sendTo: string[];
    record: ReportHistoryData.ReportHistory;
    onClose: () => void
}

const customDatePicker = ({ handleSelect }) => (
    <Box
        position="absolute"
        top="90px"
        p={3}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        zIndex="10"
        boxShadow="md"
    >
        <Grid templateColumns="repeat(7, 1fr)" gap={1}>
            {Array.from({ length: 31 }, (_, index) => (
                <GridItem display="flex" justifyContent="center">
                    <Button
                        key={index + 1}
                        onClick={() => handleSelect(index + 1)}
                        size="sm"
                        variant="outline"
                        border="none"
                    >
                        {index + 1}
                    </Button>
                </GridItem>
            ))}
        </Grid>
    </Box>
);

export const RescheduleRecurringReportModal = (props: Props) => {
    const {
        disabled,
        startDate,
        endDate,
        isOpen,
        modalTitle,
        sendOn,
        sendTo,
        record,
        onClose
    } = props;

    const [selectedFrequencyButton, setSelectedFrequencyButton] = useState("Monthly");
    const [selectedRepeatButton, setSelectedRepeatButton] = useState(sendOn || null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [emails, setEmails] = useState(sendTo || []);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [updateRecurringReport, { isLoading: isUpdating }] = useUpdateRecurringReportMutation()

    useEffect(() => {
        if (isOpen) {
            setEmails(sendTo || []);
            setSelectedRepeatButton(sendOn || null);
        }
    }, [isOpen, sendTo, sendOn]);

    const handleDaySelect = (day: number) => {
        setSelectedDay(day);
        setIsDatePickerOpen(false);

        let suffix = "th"
        if (day === 1) {
            suffix = "st"
        } else if (day === 2) {
            suffix = "nd"
        }
        if (selectedRepeatButton === "Each") {
            setSelectedRepeatButton(`Each - ${day}${suffix}`);
        } else if (selectedRepeatButton === "On the") {
            setSelectedRepeatButton(`On the - ${day}${suffix}`);
        }
    };

    const handleUpdate = () => {
        const updatedRecord = JSON.parse(JSON.stringify(record))
        updatedRecord.filters.send_to = emails
        updatedRecord.filters.send_on = selectedRepeatButton
        updateRecurringReport({ body: updatedRecord })
        handleClose()
    }

    const handleClose = () => {
        onClose()

    };

    return (
        <CustomModal
            title={modalTitle}
            handleSend={handleUpdate}
            isDisabled={emails.length === 0}
            isLoading={isUpdating}
            isOpen={isOpen}
            onClose={handleClose}
            buttonName="Update"
        >
            <Flex direction="column" gap={3} position="relative">
                <Flex direction="column" gap={1}>
                    <Text fontWeight="700" fontSize="13px">Period and locations</Text>
                    {!disabled && (
                        <p style={{ fontWeight: 700, fontSize: '14px' }}>
                            {`${formatDate(startDate)} - ${formatDate(endDate)}`} - All locations
                        </p>
                    )}
                    <Text fontSize="11px" color="#484848">From next month you will receive monthly report for the respective month.</Text>
                </Flex>
                <Flex direction="column" gap={1}>
                    <Text fontWeight="700" fontSize="13px">Frequency</Text>
                    <Text fontSize="11px" color="#484848">Specify the time you would like to repeat your reports.</Text>
                    <Flex mt={2} gap={3}>
                        <Button
                            borderRadius="full"
                            bg={selectedFrequencyButton === "Monthly" ? "gray.700" : "#f0f0f0"}
                            color={selectedFrequencyButton === "Monthly" ? "white" : "black"}
                            px="16px"
                            py="8px"
                            h="34px"
                            onClick={() => setSelectedFrequencyButton("Monthly")}
                            _hover={{
                                bg: selectedFrequencyButton === "Monthly" ? "gray.700" : "gray.200",
                            }}
                            fontWeight="700"
                            fontSize="sm"
                            fontFamily="Inter"
                        >
                            Monthly
                        </Button>
                    </Flex>
                </Flex>
                <Flex direction="column" gap={1} position="relative">
                    <Text fontWeight="700" fontSize="13px">Send on</Text>
                    <Text fontSize="11px" color="#484848">Specify the time you would like to repeat your reports.</Text>
                    <Flex mt={2} gap={3}>
                        <Button
                            borderRadius="full"
                            bg={selectedRepeatButton?.startsWith("Each") ? "gray.700" : "#f0f0f0"}
                            color={selectedRepeatButton?.startsWith("Each") ? "white" : "black"}
                            px="16px"
                            py="8px"
                            h="34px"
                            onClick={() => {
                                setSelectedRepeatButton("Each")
                                setIsDatePickerOpen(true)
                            }}
                            _hover={{
                                bg: selectedRepeatButton?.startsWith("Each") ? "gray.700" : "gray.200",
                            }}
                            fontWeight="700"
                            fontSize="sm"
                            fontFamily="Inter"
                        >
                            {selectedRepeatButton?.startsWith("Each") ? selectedRepeatButton : "Each"}
                        </Button>
                        <Button
                            borderRadius="full"
                            bg={selectedRepeatButton?.startsWith("On the") ? "gray.700" : "#f0f0f0"}
                            color={selectedRepeatButton?.startsWith("On the") ? "white" : "black"}
                            px="16px"
                            py="8px"
                            h="34px"
                            onClick={() => {
                                setSelectedRepeatButton("On the")
                                setIsDatePickerOpen(true)
                            }}
                            _hover={{
                                bg: selectedRepeatButton?.startsWith("On the") ? "gray.700" : "gray.200",
                            }}
                            fontWeight="700"
                            fontSize="sm"
                            fontFamily="Inter"
                        >
                            {selectedRepeatButton?.startsWith("On the") ? selectedRepeatButton : "On the"}
                        </Button>
                    </Flex>
                    {isDatePickerOpen && customDatePicker({ handleSelect: handleDaySelect })}
                </Flex>
                <Flex direction="column" gap={1}>
                    <Text fontWeight="700" fontSize="13px">Send to</Text>
                    <Text fontSize="11px" color="#484848">Provide the email address(es) where you would like to receive your reports.</Text>
                    <EmailInput emails={emails} setEmails={setEmails} />
                </Flex>
            </Flex>
        </CustomModal>)
}

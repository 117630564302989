import { useMemo } from 'react'
import { useGetMeasurementTypesQuery } from '../../locations/api/locationsApi'
import MultiSelect from '../components/MultiSelect'
import { NumberInput, NumberInputField, Box } from '@chakra-ui/react'
import { IconTooltip } from '../../../utils/Icon'

export const EnergyConsumptionReportFilters = ({
    selectedMeasurementTypes,
    setSelectedMeasurementTypes,
    selectedDeviceTypes,
    register,
    setValue,
}) => {
    const { data: measurementTypes } = useGetMeasurementTypesQuery({
        deviceTypeIds: selectedDeviceTypes,
    })

    const measurementTypesSelect = useMemo(
        () =>
            measurementTypes?.results.map(({ id, name }) => ({
                id,
                name,
            })),
        [measurementTypes],
    )

    return (
        <>
            <MultiSelect
                items={measurementTypesSelect}
                setSelectedOptions={setSelectedMeasurementTypes}
                selectedOptions={selectedMeasurementTypes}
                setValue={setValue}
                invalidateFields={['selectedDevices']}
                header={`Measurement type/s${selectedMeasurementTypes.length > 0
                    ? ` (${selectedMeasurementTypes.length})`
                    : ''
                    }`}
                tooltip={{
                    label: 'Please select period',
                    hasArrow: true,
                    placement: 'top',
                }}
            />
            <Box display="flex" alignItems="center" gap="10px">
                <NumberInput w="full">
                    <NumberInputField
                        w="full"
                        bg="white"
                        border="1px"
                        height="48px"
                        borderColor='border.strong'
                        textAlign="left"
                        fontSize="18px"
                        fontWeight="normal"
                        id={'ibexPrice'}
                        {...register('ibexPrice')}
                        placeholder="Custom Energy price (BGN/kWh, optional)" />
                </NumberInput>
                <IconTooltip
                    label="By default, the kWh price is calculated as an average of hourly IBEX values for the selected period. You can set your own custom kWh price if you prefer not to use the IBEX-based calculation"
                />
            </Box>
            <Box display="flex" alignItems="center" gap="10px">
                <NumberInput w="full">
                    <NumberInputField
                        w="full"
                        bg="white"
                        border="1px"
                        height="48px"
                        borderColor='border.strong'
                        textAlign="left"
                        fontSize="18px"
                        fontWeight="normal"
                        id={'carbonIntensity'}
                        {...register('carbonIntensity')}
                        placeholder="Custom Carbon intensity (gr/kWh, optional)" />
                </NumberInput>
                <IconTooltip
                    label="By default, emissions are calculated based on a standard coefficient according to Energy Maps. If desired, you can enter a customized value in the input field."
                />
            </Box>

        </>
    )
}

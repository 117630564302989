import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, Text, List, Collapse, Divider } from '@chakra-ui/react'
import { ProgressBarWrapper } from '../../../app/components/ProgressBar'
import ViewIcon from '../../../assets/icons/StackIcon'
import { calculatePercentage } from '../../../utils/helpers'
import ArrowUpIcon from '../../../assets/icons/ArrowUp'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon'

const DeviceItem = ({ device, type, totalConsumption, locationId, level = 0 }) => {
  const [showChildren, setShowChildren] = useState(false)

  const toggleChildren = () => {
    if (device.children?.length > 0) {
      setShowChildren(!showChildren)
    }
  }

  return (
    <Box>
      <Flex
        align="center"
        gap="4"
        py="5"
        _hover={device.children?.length > 0 && { bg: '#f7f9fd' }}
        px="15px"
        cursor={device.children?.length > 0 && 'pointer'}
        position="relative"
        onClick={toggleChildren}
        style={{
          marginLeft: `${level}px`,
        }}
      >
        {level > 0 && (
          <Divider
            borderWidth="thin"
            orientation="vertical"
            position="absolute"
            left="-2px"
          />
        )}

        <ProgressBarWrapper
          title={device.title}
          variant={type === 'generator' ? 'generator' : 'consumer'}
          kilowatts={
            type === 'generator' ? device.total_generated_power : device.total_consumption
          }
          percentage={calculatePercentage(
            type === 'generator'
              ? device.total_generated_power.toString()
              : device.total_consumption.toString(),
            totalConsumption,
          )}
        />
        {device.children?.length > 0 ? (
          showChildren ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          )
        ) : null}
        <Link
          to={`/deviceInfo?device=${device.id}&location=${locationId}`}
          onClick={(e) => e.stopPropagation()}
        >
          <ViewIcon />
        </Link>
      </Flex>
      {device.children?.length > 0 && (
        <Collapse in={showChildren} animateOpacity>
          <Box pl="6">
            {device.children.map((child) => (
              <DeviceItem
                key={child.id}
                device={child}
                type={type}
                totalConsumption={totalConsumption}
                locationId={locationId}
                level={level + 1}
              />
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  )
}

const DevicesContainer = ({ devices, totalConsumption, title, type, locationId }) => {
  const [showAll, setShowAll] = useState(false)

  const handleShowMore = () => {
    setShowAll(!showAll)
  }

  const alwaysVisibleItems = devices.slice(0, 5)
  const additionalItems = devices.slice(5)

  return (
    <Box py="16px" transition={{ height: '200ms' }}>
      <Text px="15px" fontSize="14px" fontWeight="700" color="text.secondary">
        {title}
      </Text>
      <List overflow="hidden">
        {alwaysVisibleItems.map((device) => (
          <DeviceItem
            key={device.id}
            device={device}
            type={type}
            totalConsumption={totalConsumption}
            locationId={locationId}
          />
        ))}
        <Collapse
          in={showAll}
          animateOpacity
          transition={{ enter: { duration: 1 }, exit: { duration: 1 } }}
        >
          {additionalItems.map((device) => (
            <DeviceItem
              key={device.id}
              device={device}
              type={type}
              totalConsumption={totalConsumption}
              locationId={locationId}
            />
          ))}
        </Collapse>
      </List>

      {devices.length > 5 && (
        <Flex justifyContent="space-around">
          <Text onClick={handleShowMore} cursor="pointer" variant="ghost">
            {showAll
              ? `See only top ${type === 'generator' ? 'generators' : 'consumers'}`
              : `See all ${type === 'generator' ? 'generators' : 'consumers'}`}
            {showAll ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default DevicesContainer

import {
  GridItem,
  Text,
  Skeleton,
  Box,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReportFilterContext } from '../../../../../context/ReportFilterContext'
import { setTemplateCount } from '../../../../../utils/helpers'
import { useGetReportsHistoryQuery } from '../../../api/reportsApi'
import { ReportHistoryTemplateRow } from './ReportHistoryTemplateRow'

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    background: 'gray.200',
    borderRadius: '0 0.25em 0.25em 0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'blue.500',
    borderRadius: '0.25em',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
}


export default function ReportTemplateHistoryGrid({ reportType }) {
  const { setBookmarkTemplateCount, setRecurringTemplateCount } = useReportFilterContext()

  const location = useLocation()
  const pageNumber = Number(new URLSearchParams(location.search).get('page') || 1)
  const { data: reportsHistoryData, isLoading, isFetching } = useGetReportsHistoryQuery({
    page: Number(pageNumber),
    filterType: reportType,
  })
  const reportsHistory = reportsHistoryData?.results
  const reportsCount = reportsHistoryData?.count

  useEffect(() => {
    if (!isLoading || !isFetching) {
      setTemplateCount(
        setBookmarkTemplateCount,
        setRecurringTemplateCount,
        reportType,
        reportsCount,
      )
    }
  }, [])

  return (
    <GridItem sx={{ ...scrollBarStyle }} bg="white" rounded="lg" pl="24px" minHeight={"540px"} maxHeight={'540px'} overflow={'scroll'}>
      {reportsHistory?.length ? (
        <Skeleton isLoaded={!isFetching}>
          <ReportHistoryTemplateRow reportsHistory={reportsHistory} />
        </Skeleton>
      ) : (
        <Skeleton isLoaded={!isLoading}>
          <Box height={540} display={'flex'} justifyContent={"center"}>
            <Text pt={200}>{`There no ${reportType} template yet`}</Text>
          </Box>
        </Skeleton>
      )}

    </GridItem>
  )
}

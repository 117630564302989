import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Text } from '@chakra-ui/react';
import { addCommasToNumber } from '../../../../../utils/helpers';
import PercentageChange from './PercentageChange';

const DonutChart = ({ data, labels, total, percentageChange, colors }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: colors,
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: false,
                    },
                },
                cutout: '70%',
            },
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data, labels, colors]);

    const formattedTotal = addCommasToNumber(total)

    return (
        <div className='donutChart' style={{ position: 'relative', width: '200px', height: '200px' }}>
            <canvas ref={chartRef} />
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                }}
            >
                <Text fontFamily="Inter" fontWeight={700} fontSize="14px">{formattedTotal} <span style={{ fontSize: "12px", color: "#484848" }}>kWh</span></Text>
                <PercentageChange percentageChange={percentageChange} />
            </div>
        </div>
    );
};

export default DonutChart;

import React, { useMemo } from 'react'
import { Box, Grid, Skeleton } from '@chakra-ui/react'
import { CreateDeviceGroupModal } from '../components/CreateDeviceGroupModal'
import { DeviceGroupTable } from '../components/DeviceGroupTable'
import { useGetDeviceGroupListQuery } from '../api/deviceGroupConfigurationApi'
import { useGetAllDevicesQuery } from '../../automations/api/automationsApi'
import { getUngroupedDevices } from '../utils'

export const DeviceGroupConfiguration = () => {
  const { data: groupDeviceList, isLoading: isGroupListLoading } =
    useGetDeviceGroupListQuery()

  const { data: { results: devices = [] } = {}, isLoading } = useGetAllDevicesQuery()

  const ungroupedDevices = useMemo(() => {
    return getUngroupedDevices(devices, groupDeviceList)
  }, [groupDeviceList, devices])

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={5}>
      <Box>
        <Skeleton isLoaded={!isLoading || !isGroupListLoading}>
          <CreateDeviceGroupModal ungroupedDevices={ungroupedDevices} />
        </Skeleton>
      </Box>
      <Box w="full" bg="white" rounded="lg">
        <Skeleton isLoaded={!isGroupListLoading || !isLoading}>
          <DeviceGroupTable
            ungroupedDevices={ungroupedDevices}
            groupDeviceList={groupDeviceList}
          />
        </Skeleton>
      </Box>
    </Grid>
  )
}

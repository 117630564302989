import { InfoOutlineIcon } from '@chakra-ui/icons'
import { chakra, ImageProps, forwardRef, Tooltip } from '@chakra-ui/react'

export const Icon = forwardRef<ImageProps, 'img'>((props, ref) => {
  return <chakra.img ref={ref} {...props} />
})

export function IconTooltip({ label }) {
  return (
    <Tooltip
      w="full"
      label={label}
      placement="top"
      hasArrow={true}
      shouldWrapChildren
    >
      <InfoOutlineIcon fontSize="20px" />
    </Tooltip>
  )
}
import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
} from '@chakra-ui/react';

export default function CustomModal({ children, title, handleSend, isOpen, onClose, isDisabled, isLoading, buttonName }) {
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody sx={{ pb: 0 }}>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={isDisabled}
                        isLoading={isLoading}
                        padding="0"
                        width="100%"
                        onClick={handleSend}
                        colorScheme="blue"
                    >
                        {buttonName}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
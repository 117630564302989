import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, Grid } from '@chakra-ui/react'
import React from 'react'
import Header from '../../../app/components/Header'
import { ReportTemplateHistoryPagination } from './components/ReportTemplateHistory/ReportTemplateHistoryPagination'

export const ReportHistory = () => {
  return (
    <Box>
      <Header title="Report History" />
      <Box p="24px">
        <Tabs bg="white" colorScheme="white" isFitted variant="enclosed" isLazy>
          <TabList mb="1em">
            <Tab>Bookmark reports</Tab>
            <Tab>Recurring reports</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ReportTemplateHistoryPagination reportType="bookmark" />
            </TabPanel>
            <TabPanel>
              <ReportTemplateHistoryPagination reportType="recurring" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

import React from 'react'
import {
  Box,
  Grid,
  GridItem,
  Divider,
  Text,
  Flex,
  Skeleton,
} from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'
import { Pagination } from '../../../../../app/components/Pagination'
import {
  useGetReportsHistoryQuery,
} from '../../../api/reportsApi'
import { ReportHistoryTemplateRow } from './ReportHistoryTemplateRow'

type Props = {
  reportType: string
}

export const ReportTemplateHistoryPagination = ({ reportType }: Props) => {
  const location = useLocation()
  const pageNumber = Number(new URLSearchParams(location.search).get('page') || 1)
  const { data: reportsHistoryData, isLoading } = useGetReportsHistoryQuery({
    page: Number(pageNumber),
    filterType: reportType,
  })

  const reportsHistory = reportsHistoryData?.results
  const totalPages = reportsHistoryData?.total_pages
  const reportsCount = reportsHistoryData?.count

  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      gap="0"
      bg="white"
      rounded="lg"
      alignItems="center"
    >
      <GridItem colSpan={3} py="16px" pl="24px">
        <Flex align="center" lineHeight="18px" h="30px">
          <Text color="text.dark">Total Reports ({reportsCount})</Text>
        </Flex>
      </GridItem>
      <GridItem colSpan={2}>
        <Flex direction="row" gap="2" justifyContent="left" mr="24px">
          {/* <SingleSelect
                  key="location-id"
                  items={locations}
                  setSelectedOption={(value) => {
                    setLocationId(value as number)
                  }}
                  selectedOption={locationId}
                  header="Select location"
                />
                <SingleSelect
                  key="period-id"
                  items={[
                    { name: '1 day', id: '1' },
                    { name: '1 week', id: '2' },
                    { name: '1 month', id: '3' },
                    { name: '3 months', id: '4' },
                    { name: '6 months', id: '5' },
                  ]}
                  setSelectedOption={(value) => {
                    setPeriodId(value as number)
                  }}
                  selectedOption={periodId}
                  header="Select Period"
                /> */}
        </Flex>
      </GridItem>

      <GridItem colSpan={5}>
        <Divider />
      </GridItem>

      {reportsHistory?.length ? (
        <GridItem colSpan={5}>
          <ReportHistoryTemplateRow reportsHistory={reportsHistory} />
        </GridItem>
      ) : (
        <Skeleton isLoaded={!isLoading}>
          <Box height={'80%'} display={'flex'} justifyContent={'center'}>
            <Text>{`There no ${reportType} template yet`}</Text>
          </Box>
        </Skeleton>
      )}
      {totalPages > 1 && (
        <GridItem colSpan={5}>
          <Pagination totalPages={totalPages} currentPage={pageNumber} />
        </GridItem>
      )}
    </Grid>
  )
}

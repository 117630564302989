import { createIcon } from '@chakra-ui/icons'

const CostForConsumptionIcon = createIcon({
  displayName: 'CostForConsumptionIcon',
  viewBox: '0 0 32 32',
  path: (
    <g id="Icon/ cost for consumption">
      <path
        id="Ellipse 27"
        d="M30.6246 14.3271C31.327 14.2467 31.9669 14.7513 31.9908 15.4578C32.0946 18.5161 31.319 21.55 29.7427 24.1937C27.9473 27.205 25.2126 29.5433 21.9589 30.849C18.7052 32.1547 15.1127 32.3556 11.7337 31.4207C8.35473 30.4859 5.37633 28.4671 3.2564 25.6747C1.13648 22.8824 -0.0076059 19.471 3.80297e-05 15.9651C0.00768196 12.4592 1.16663 9.05287 3.29871 6.26977C5.43079 3.48668 8.41797 1.4809 11.801 0.560819C14.771 -0.246945 17.9018 -0.178653 20.8197 0.743167C21.4938 0.956124 21.8078 1.70807 21.5417 2.36298C21.2755 3.0179 20.53 3.32671 19.8528 3.12405C17.4578 2.4074 14.9014 2.3706 12.4728 3.03109C9.63109 3.80396 7.12187 5.48881 5.33092 7.82661C3.53997 10.1644 2.56645 13.0257 2.56003 15.9707C2.55361 18.9157 3.51464 21.7812 5.29538 24.1268C7.07612 26.4724 9.57797 28.1682 12.4163 28.9534C15.2547 29.7387 18.2723 29.5699 21.0055 28.4731C23.7386 27.3763 26.0358 25.4122 27.5439 22.8827C28.8327 20.721 29.4843 18.2487 29.4377 15.7493C29.4245 15.0425 29.9223 14.4074 30.6246 14.3271Z"
        fill="#707070"
      />
      <path
        id="$"
        d="M15.4567 24C15.1974 24 14.9853 23.9159 14.8203 23.7476C14.6553 23.5906 14.5728 23.3943 14.5728 23.1588V22.0147C13.8538 21.9699 13.2115 21.8633 12.6457 21.6951C12.0918 21.5156 11.6144 21.3193 11.2137 21.1062C10.9426 20.9604 10.7717 20.7641 10.701 20.5174C10.6421 20.2706 10.6597 20.0351 10.754 19.8107C10.8483 19.5864 11.0074 19.4294 11.2314 19.3396C11.4553 19.2499 11.7205 19.2892 12.0269 19.4574C12.357 19.6369 12.7872 19.8107 13.3175 19.979C13.8597 20.136 14.5079 20.2145 15.2623 20.2145C16.1462 20.2145 16.7827 20.0687 17.1716 19.7771C17.5606 19.4742 17.755 19.0985 17.755 18.6498C17.755 18.2797 17.6195 17.9769 17.3484 17.7413C17.0773 17.5058 16.5941 17.3151 15.8987 17.1693L14.0247 16.7823C11.8914 16.3449 10.8247 15.2794 10.8247 13.5857C10.8247 12.6435 11.1607 11.8528 11.8325 11.2135C12.5161 10.5629 13.4295 10.1703 14.5728 10.0358V8.84122C14.5728 8.60568 14.6553 8.40939 14.8203 8.25237C14.9853 8.08412 15.1974 8 15.4567 8C15.7042 8 15.9105 8.08412 16.0755 8.25237C16.2523 8.40939 16.3407 8.60568 16.3407 8.84122V10.0189C16.8122 10.0638 17.3072 10.1703 17.8258 10.3386C18.3561 10.5068 18.8158 10.7368 19.2048 11.0284C19.4287 11.1966 19.5583 11.3929 19.5937 11.6172C19.6291 11.8304 19.5878 12.0322 19.4699 12.2229C19.3639 12.4024 19.193 12.5258 18.9572 12.5931C18.7333 12.6491 18.4681 12.5931 18.1617 12.4248C17.8199 12.2341 17.4368 12.0827 17.0125 11.9706C16.5882 11.8472 16.0814 11.7855 15.4921 11.7855C14.7378 11.7855 14.1426 11.9481 13.7065 12.2734C13.2822 12.5875 13.07 13.0025 13.07 13.5184C13.07 13.8998 13.1997 14.2082 13.459 14.4437C13.7183 14.6681 14.1602 14.8475 14.7849 14.9821L16.712 15.3859C17.8434 15.6215 18.6744 15.9972 19.2048 16.5131C19.7351 17.0179 20.0003 17.6852 20.0003 18.5152C20.0003 19.4574 19.6585 20.2313 18.9749 20.837C18.3031 21.4315 17.425 21.8072 16.3407 21.9642V23.1588C16.3407 23.3943 16.2582 23.5906 16.0932 23.7476C15.9282 23.9159 15.716 24 15.4567 24Z"
        fill="#707070"
      />
      <rect
        id="Rectangle 1109"
        x="30.667"
        y="9.33331"
        width="8"
        height="2.66667"
        rx="1.33333"
        transform="rotate(-180 30.667 9.33331)"
        fill="#707070"
      />
    </g>
  ),
})

export default CostForConsumptionIcon

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //explicit keys
  phoneS: '320px',
  phoneM: '375px',
  phoneL: '425px',
  tablet: '768px',
  laptopM: '1024px',
  laptopL: '1440px',
  desktop: '1920px',
  //short keys
  sm: '375px',
  md: '768px',
  lg: '1920px',
}

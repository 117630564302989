import { Flex, Box, Tag, TagLabel, TagCloseButton, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";

export default function EmailInput({ emails, setEmails }) {
    const [error, setError] = useState('')
    const [inputValue, setInputValue] = useState('');


    const isValidEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    };

    const addEmail = (email) => {
        if (email && isValidEmail(email)) {
            setEmails((oldEmails) =>[...oldEmails, email]);
            setInputValue('');
            setError('');
        } else {
            setError('Please enter a valid email');
        }
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter((email) => email !== emailToRemove));
    };

    return (
        <Flex display="flex" direction="column">
            <Box borderWidth="1px" borderRadius="md" p="2" bg="white" borderColor={error && 'red.500'}>
                <Flex wrap="wrap" gap={2}>
                    {emails.map((email, index) => (
                        <Tag key={`${email}-${index}`} backgroundColor="#E8EEFD" borderRadius="8px">
                            <TagLabel>{email}</TagLabel>
                            <TagCloseButton onClick={() => removeEmail(email)} />
                        </Tag>
                    ))}
                    <Input
                        variant="unstyled"
                        placeholder={emails.length === 0 ? "To" : ""}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && inputValue) {
                                addEmail(inputValue);
                                e.preventDefault();
                            }
                        }}
                        flex="1"
                        minW="150px"
                    />
                </Flex>
            </Box>
            {error && <Text fontSize='xs' color="red.500">{error}</Text>}
        </Flex>
    )
}
import { DeviceGroupListType, DeviceResult, SelectOption } from "./api/types"

export const getUngroupedDevices = (devices: DeviceResult[], devicesGroup: DeviceGroupListType[]) => {
  const usedDevices = devicesGroup?.map((x) =>
    x.devices.map(y => y.id)
  ).flat()
  const unRegisteredDevices = devices.filter(x => !usedDevices?.includes(x.id))
  return unRegisteredDevices || []
}


export const generateSelectOptions = (option: any): SelectOption[] => {
  return option?.map((x) => {
    return { label: x.title, value: x.id }
  }) || []
}
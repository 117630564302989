import { useCallback } from 'react'
import {
  useGetDevicesCsvReportMutation,
  useSendCsvReportViaEmailMutation,
} from '../../locations/api/locationsApi'

export const useSendCsvReport = () => {
  const [getDevicesCsvReport] = useGetDevicesCsvReportMutation()
  const [sendCsvReportViaEmail] = useSendCsvReportViaEmailMutation()

  const sendCsvReport = useCallback(
    async (data) => {
      try {
        const res = await getDevicesCsvReport({
          from_date: data?.from_date,
          to_date: data?.to_date,
          device_ids: data?.device_ids,
          report_name: data?.report_name,
          report_type: data?.report_type,
          ibex_price: data?.ibex_price,
          carbon_intensity: data?.carbon_intensity,
        }).unwrap()

        await sendCsvReportViaEmail({
          rows_data: res,
          file_name: data?.report_name,
          emails: data?.send_to,
          description: 'test send',
        }).unwrap()
      } catch (error) {
        console.error('Error sending CSV report:', error)
      }
    },
    [getDevicesCsvReport, sendCsvReportViaEmail],
  )

  return { sendCsvReport }
}

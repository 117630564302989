import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useLazyGetProfileQuery } from '../features/auth/api/authApi'

interface User {
  email: string
  id: number
  name: string
  shelly_domain: string
  shelly_key: string
}

const UserContext = createContext<{
  user: User | null
  setUser: (u: User) => void
  isError: boolean
}>({
  user: null,
  setUser: () => {},
  isError: false,
})

export const useUser = () => {
  return useContext(UserContext)
}

export const UserContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [user, setUser] = useState<User | null>(null)
  const token = window.localStorage.getItem('aiot_t')
  const [getProfile, { isError }] = useLazyGetProfileQuery()

  useEffect(() => {
    if (token && !user) {
      getProfile()
        .unwrap()
        .then((user) => {
          setUser(user)
        })
    }
  }, [token, getProfile, user])

  return (
    <UserContext.Provider value={{ user, setUser, isError }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext

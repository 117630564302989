
export const REQUIRED = '* Required field'

export const DATA_SOURCE = [
    { label: "MQTT", value: 'mqtt' },
    { label: "Shelly Python", value: "shelly" },
    { label: "Shelly Integrator", value: "shelly_integrator" },
    { label: "Sensibo", value: "sensibo" },
    { label: "No Source", value: "no_source" }]

export const DEVICE_CATEGORY = [
    {
        label: "Emeter",
        value: "emeter"
    },
    {
        label: "Sensor",
        value: "sensor"
    },
    {
        label: "Relay",
        value: "relay"
    },
    {
        label: "Energy generator",
        value: "energy_generator"
    },
    {
        label: "Emeter relay",
        value: "emeter_relay"
    },
    {
        label: "Energy plant logger",
        value: "energy_plant_logger"
    },
    {
        label: "Climate control",
        value: "climate_control"
    },
    {
        label: "Air quality",
        value: "air_quality",
    },
    {
        label: "Motion sensor",
        value: "motion_sensor",
    },
    {
        label: "Dimmer",
        value: "dimmer",
    },
]
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Grid,
  GridItem,
  Flex,
  Button,
  Input,
  Text,
  Stack,
  NumberInput,
  NumberInputField,
  Box,
  useDisclosure,
} from '@chakra-ui/react'
import Header from '../../../app/components/Header'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { useForm } from 'react-hook-form'
import { useGetAllLocationsQuery } from '../api/reportsApi'
import { IFormFields } from '../api/types'
import { useGetReportAsPDFMutation } from '../../../app/services/api/api'
import SingleSelect from '../components/SingleSelect'
import MultiSelect from '../components/MultiSelect'
import {
  useGetDeviceTypesQuery,
  useGetUserDevicesQuery,
  useGetDevicesCsvReportMutation,
  useGetEnergyTypesQuery,
} from '../../locations/api/locationsApi'
import { useGetDeviceGroupListQuery } from '../../deviceConfiguration/api/deviceGroupConfigurationApi'
import { TrendReportFilters } from '../forms/TrendReportFilters'
import { EnergyConsumptionReportFilters } from '../forms/EnergyConsumptionReportFilters'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai'
import SendEmail from './components/SendEmail'
import { IconTooltip } from '../../../utils/Icon'
import PreviewDocumentDialog from './components/PreviewDocumentDialog'
import { IReportPdfResponse } from '../api/trendReportPdf'
import { GenerateReportHistoryTabs } from './components/ReportTemplateHistory/GenerateReportHistoryTabs'
import { useReportFilterContext } from '../../../context/ReportFilterContext'

enum DIRECTION {
  COL = 'column',
  ROW = 'row',
}

enum ReportType {
  Pdf = 'PDF',
  Csv = 'Csv',
}

export const REPORT_TYPES = [
  { id: 1, name: 'Trend report' },
  { id: 2, name: 'Billing report' },
  { id: 3, name: 'Energy Consumption Report' },
]
export enum ReportPeriodType {
  Day = 'Day',
  Month = 'Month',
}

const isReportInDays = (startDate: Date, endDate: Date): boolean => {
  const msInADay = 1000 * 60 * 60 * 24
  const differenceInDays = Math.abs(endDate.getTime() - startDate.getTime()) / msInADay
  return differenceInDays <= 31
}

const GenerateReport = () => {
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure()

  const [pdfReportData, setPdfReportData] = useState<IReportPdfResponse | null>(null)
  const [reportPeriodType, setReportPeriodType] = useState<ReportPeriodType | null>(null)
  const [reportData, setReportData] = useState()
  const { data: locations } = useGetAllLocationsQuery()
  const { data: devicesGroups } = useGetDeviceGroupListQuery()
  const { reportFilters } = useReportFilterContext()

  const [getReportAsPDF, { isLoading: isPdfLoading }] = useGetReportAsPDFMutation()
  const [getDevicesCsvReport, { isLoading: isCsvLoading }] =
    useGetDevicesCsvReportMutation()

  // const [getTrendReportData, { isLoading: isPdfLoading }] =
  //   useGetTrendReportDataMutation()

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid: formIsValid },
  } = useForm<IFormFields>()

  useEffect(() => {
    setValue(
      'selectedLocations',
      locations?.results.map((location: any) => location.id.toString()),
    )
  }, [locations, setValue])

  const setSelectedLocations = (selectedLocations: string[]) => {
    setValue('selectedLocations', selectedLocations)
  }
  const setSelectedDeviceTypes = (selectedDeviceTypes: string[]) => {
    setValue('selectedDeviceTypes', selectedDeviceTypes)
  }
  const setSelectedDevicesGroups = (selectedDevicesGroups: string[]) => {
    setValue('selectedDevicesGroups', selectedDevicesGroups)
  }
  const setSelectedEnergyType = (selectedEnergyType: number) => {
    setValue('selectedEnergyType', selectedEnergyType)
  }
  const setSelectedReportType = (selectedReportType: number) => {
    setValue('selectedReportType', selectedReportType)
  }
  const setSelectedTotalDetailed = (selectedTotalDetailed: number) => {
    setValue('selectedTotalDetailed', selectedTotalDetailed)
  }
  const setSelectedDevices = (selectedDevices: string[]) => {
    setValue('selectedDevices', selectedDevices)
  }
  const setSelectedMeasurementTypes = (selectedMeasurementTypes: string[]) => {
    setValue('selectedMeasurementTypes', selectedMeasurementTypes)
  }

  const startDate = watch('startDate') || null
  const endDate = watch('endDate') || null
  const selectedEnergyType = watch('selectedEnergyType') || null
  const selectedReportType = watch('selectedReportType') || null
  const selectedTotalDetailed = watch('selectedTotalDetailed') || null
  const selectedLocations = watch('selectedLocations') || []
  const selectedDeviceTypes = watch('selectedDeviceTypes') || []
  const selectedDevicesGroups = watch('selectedDevicesGroups') || []
  const selectedDevices = watch('selectedDevices') || []
  const selectedMeasurementTypes = watch('selectedMeasurementTypes') || []

  const generateCsvData = (data) => {
    const headers = Object.keys(data[0])
    const csvRows = [
      headers.join(','),
      ...data.map((row) =>
        headers.map((header) => JSON.stringify(row[header] || '')).join(','),
      ),
    ]

    return csvRows.join('\n')
  }

  async function onSubmit(formData: IFormFields, saveAs: ReportType) {
    const toastId = toast.info('Generating report...', {
      style: { padding: '10px 20px' },
      autoClose: false,
      isLoading: true,
      progress: undefined,
    })

    try {
      if (saveAs === ReportType.Pdf) {
        if (selectedReportType === 1 || selectedReportType === 2) {
          await getReportAsPDF({
            energy_type: formData.selectedEnergyType || null,
            location_ids: formData.selectedLocations,
            device_type: formData.selectedDeviceTypes || [],
            device_groups: formData.selectedDevicesGroups,
            from_date: formData.startDate.toLocaleDateString('en-US'),
            to_date: formData.endDate.toLocaleDateString('en-US'),
            device_ids: formData.selectedDevices,
            report_name: formData.reportName,
            report_type: formData.selectedTotalDetailed || 1,
            isBillingReport: selectedReportType === 2,
            ibex_price: formData.ibexPrice,
          }).unwrap()
        }
        if (isReportInDays(new Date(formData.startDate), new Date(formData.endDate))) {
          setReportPeriodType(ReportPeriodType.Day)
        } else {
          setReportPeriodType(ReportPeriodType.Month)
        }

        // const reportData: IReportPdfResponse = await getTrendReportData({
        //   energy_type: formData.selectedEnergyType || null,
        //   location_ids: formData.selectedLocations,
        //   device_type: formData.selectedDeviceTypes || [],
        //   device_groups: formData.selectedDevicesGroups,
        //   from_date: formData.startDate.toLocaleDateString('en-US'),
        //   to_date: formData.endDate.toLocaleDateString('en-US'),
        //   device_ids: formData.selectedDevices,
        //   report_name: formData.reportName,
        //   report_type: formData.selectedTotalDetailed,
        // } as IReportPdfRequest).unwrap()

        // setPdfReportData(reportData)

        toast.update(toastId, {
          render: 'PDF report generated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      } else if (saveAs === ReportType.Csv) {
        const res = await getDevicesCsvReport({
          from_date: formData.startDate.toLocaleDateString('en-US'),
          to_date: formData.endDate.toLocaleDateString('en-US'),
          device_ids: formData.selectedDevices,
          report_name: formData.reportName,
          report_type: formData.selectedReportType,
          ibex_price: formData.ibexPrice,
          carbon_intensity: formData.carbonIntensity,
        }).unwrap()

        setReportData(res)

        const csvData = generateCsvData(res)
        const csvBlob = new Blob([csvData], { type: 'text/csv' })
        const csvUrl = URL.createObjectURL(csvBlob)

        const a = document.createElement('a')
        a.href = csvUrl
        a.download = `${formData.reportName}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        toast.update(toastId, {
          render: 'CSV report generated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      }
    } catch (_error) {
      toast.update(toastId, {
        render: `Failed to generate report`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      })
    }
  }

  const updateReportFilter = useCallback(() => {
    if (reportFilters) {
      setValue('selectedLocations', reportFilters.location_ids);
      setValue('startDate', new Date(reportFilters.from_date));
      setValue('endDate', new Date(reportFilters.to_date));
      setValue('selectedDevicesGroups', reportFilters.groups_ids);
      setValue('selectedDeviceTypes', reportFilters.device_types);
      setValue('selectedEnergyType', Number(reportFilters.energy_types));
      setValue('selectedReportType', Number(reportFilters.report_type));
      setValue('selectedDevices', reportFilters.device_ids);
      setValue('selectedTotalDetailed', Number(reportFilters.total_detailed));
      setValue('selectedMeasurementTypes', reportFilters.measurement_types);
      setValue('reportName', reportFilters.report_name);
    }
  }, [reportFilters, setValue]);

  useEffect(() => {
    updateReportFilter()
  }, [updateReportFilter])


  const handleReset = () => {
    reset({ reportName: '' })
    setValue(
      'selectedLocations',
      locations?.results.map((location: any) => location.id.toString()),
    )
    setValue('startDate', null)
    setValue('endDate', null)
    setValue('selectedDevicesGroups', [])
    setValue('selectedDeviceTypes', [])
    setValue('selectedEnergyType', null)
    setValue('selectedReportType', null)
    setValue('selectedDevices', [])
    setValue('selectedTotalDetailed', 1)
    setValue('selectedMeasurementTypes', [])
    setValue('selectedDevices', [])
    setValue('selectedTotalDetailed', null)
  }

  const { data: userDevices } = useGetUserDevicesQuery({
    locationIds: selectedLocations,
    deviceTypeIds: selectedDeviceTypes,
    deviceGroups: selectedDevicesGroups,
    measurementTypeIds: selectedMeasurementTypes,
    energyType: selectedEnergyType,
  })

  const { data: deviceTypes } = useGetDeviceTypesQuery({
    groupIds: selectedDevicesGroups,
  })
  const { data: energyTypes } = useGetEnergyTypesQuery({
    deviceIds: selectedDevices,
  })

  const locationSelect = locations?.results.map((x) => {
    return { id: x.id, name: x.title }
  })

  const deviceTypeSelect = useMemo(
    () =>
      deviceTypes?.results.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [deviceTypes],
  )

  const devicesGroupsSelect = useMemo(
    () =>
      devicesGroups?.map(({ id, name }) => ({
        id,
        name,
      })),
    [devicesGroups],
  )

  const energyTypesSelect = useMemo(
    () =>
      energyTypes?.results.map(({ id, name }) => ({
        id,
        name,
      })),
    [energyTypes],
  )
  const reportTypesSelect = REPORT_TYPES.map(({ id, name }) => ({
    id,
    name,
  }))

  const userDevicesSelect = useMemo(
    () =>
      userDevices?.results.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [userDevices],
  )

  const handleOpenModal = () => {
    // handleSubmit((data) => onSubmit(data, ReportType.Pdf))()
    onOpen()
  }

  const onClosePreviewModal = () => {
    setPdfReportData(null)
    onClose()
  }

    
  return (
    <>
      <Header title="Reports" />

      <Grid templateColumns={`repeat(${'2'}, 1fr)`}>
        <GridItem h="100%" w="95%">
          <Flex display="flex" direction={DIRECTION.COL} gap={5} as="form">
            <Flex display="flex" direction={DIRECTION.ROW} gap={5}>
              <Button
                alignSelf="flex-start"
                colorScheme="button.primary"
                size="sm"
                onClick={handleReset}
              >
                Reset filters
              </Button>
              <SendEmail
                reportType="csv"
                reportName={watch('reportName')}
                reportData={reportData}
                disabled={!reportData}
                startDate={watch('startDate')}
                endDate={watch('endDate')}
                tooltip="Please populate the required fields first"
              />
            </Flex>
            {/* @ts-ignore */}
            <DateRangeInputs
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              selectedStartName="startDate"
              selectedEndName="endDate"
              startDate={startDate}
              endDate={endDate}
              isRequired
              highlightOnError
            />
            <MultiSelect
              items={locationSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedLocations}
              selectedOptions={selectedLocations}
              setValue={setValue}
              required={selectedLocations.length === 0}
              invalidateFields={['selectedDevices']}
              header={`Select location/s${
                selectedLocations.length > 0 ? ` (${selectedLocations.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <MultiSelect
              items={devicesGroupsSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDevicesGroups}
              selectedOptions={selectedDevicesGroups}
              setValue={setValue}
              invalidateFields={[
                'selectedDeviceTypes',
                'selectedEnergyType',
                'selectedDevices',
              ]}
              header={`Select device group/s${
                selectedDevicesGroups.length > 0
                  ? ` (${selectedDevicesGroups.length})`
                  : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <MultiSelect
              items={deviceTypeSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDeviceTypes}
              selectedOptions={selectedDeviceTypes}
              setValue={setValue}
              invalidateFields={['selectedMeasurementTypes', 'selectedDevices']}
              header={`Select device type/s${
                selectedDeviceTypes.length > 0 ? ` (${selectedDeviceTypes.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <SingleSelect
              key="energy-type"
              items={energyTypesSelect}
              isDisabled={!startDate && !endDate}
              {...register('selectedEnergyType')}
              setSelectedOption={setSelectedEnergyType}
              selectedOption={selectedEnergyType}
              setValue={setValue}
              invalidateFields={['selectedDevices']}
              header="Select energy type"
              required={selectedEnergyType === null}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <SingleSelect
              key="report-type"
              items={reportTypesSelect}
              isDisabled={!startDate && !endDate}
              {...register('selectedReportType')}
              setSelectedOption={setSelectedReportType}
              selectedOption={selectedReportType}
              required={selectedReportType === null}
              header="Select report type"
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            {selectedReportType === 1 ? (
              <TrendReportFilters
                selectedEnergyType={selectedEnergyType}
                selectedTotalDetailed={selectedTotalDetailed}
                setSelectedTotalDetailed={setSelectedTotalDetailed}
                selectedMeasurementTypes={selectedMeasurementTypes}
                setSelectedMeasurementTypes={setSelectedMeasurementTypes}
                selectedDeviceTypes={selectedDeviceTypes}
                register={register}
                setValue={setValue}
              />
            ) : null}
            {selectedReportType === 2 ? (
              <Box display="flex" alignItems="center" gap="10px">
                <NumberInput w="full">
                  <NumberInputField
                    w="full"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor="border.strong"
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id={'ibexPrice'}
                    {...register('ibexPrice')}
                    placeholder="Custom Energy price (BGN/kWh, optional)"
                  />
                </NumberInput>
                <IconTooltip label="By default, the kWh price is calculated as an average of hourly IBEX values for the selected period. You can set your own custom kWh price if you prefer not to use the IBEX-based calculation" />
              </Box>
            ) : null}
            {selectedReportType === 3 ? (
              <EnergyConsumptionReportFilters
                selectedMeasurementTypes={selectedMeasurementTypes}
                setSelectedMeasurementTypes={setSelectedMeasurementTypes}
                selectedDeviceTypes={selectedDeviceTypes}
                register={register}
                setValue={setValue}
              />
            ) : null}
            <MultiSelect
              items={userDevicesSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDevices}
              selectedOptions={selectedDevices}
              required={selectedDevices.length === 0}
              header={`Select device/s${
                selectedDevices.length > 0 ? ` (${selectedDevices.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <Input
              w="full"
              isDisabled={!startDate && !endDate}
              bg="white"
              border="1px"
              height="48px"
              borderColor={watch('reportName') ? 'border.strong' : 'red.500'}
              textAlign="left"
              fontSize="18px"
              fontWeight="normal"
              id={'reportName'}
              {...register('reportName', { required: true })}
              placeholder="Enter report name"
            />
            <Stack>
              <Text fontSize="xs">
                * To generate a report select a period and fill every{' '}
                <span style={{ color: '#d02534' }}>red</span> field
              </Text>
              <Flex w="full" direction="row" gap={3}>
                <Button
                  alignSelf="flex-end"
                  colorScheme="button.primary"
                  type="submit"
                  isLoading={isPdfLoading}
                  disabled={
                    isPdfLoading ||
                    isCsvLoading ||
                    !startDate ||
                    !endDate ||
                    selectedLocations.length === 0 ||
                    selectedDevices.length === 0 ||
                    !selectedReportType ||
                    !formIsValid ||
                    !selectedEnergyType
                  }
                  w="full"
                  h={12}
                  onClick={handleSubmit((data) => {
                    if (selectedReportType !== 3) {
                      onSubmit(data, ReportType.Pdf)
                    }
                    if(selectedReportType === 3) {
                      handleOpenModal()
                    }
                  })}
                >
                  <AiOutlineFilePdf
                    style={{
                      marginRight: '0.5em',
                      width: 26,
                      height: 26,
                    }}
                  />
                  {selectedReportType === 3
                    ? 'Preview PDF Report'
                    : 'Download PDF Report'}
                </Button>

                <Button
                  alignSelf="flex-end"
                  colorScheme="button.primary"
                  type="submit"
                  isLoading={isCsvLoading}
                  disabled={
                    isPdfLoading ||
                    isCsvLoading ||
                    !startDate ||
                    !endDate ||
                    selectedLocations.length === 0 ||
                    selectedDevices.length === 0 ||
                    !selectedReportType ||
                    !formIsValid ||
                    !selectedEnergyType
                  }
                  w="full"
                  h={12}
                  fontSize={'0.875rem'}
                  onClick={handleSubmit((data) => onSubmit(data, ReportType.Csv))}
                >
                  <AiOutlineFileExcel
                    style={{
                      marginRight: '0.25em',
                      width: 26,
                      height: 26,
                    }}
                  />
                  Download CSV Report
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </GridItem>
        <GridItem>
          <GenerateReportHistoryTabs />
        </GridItem>
        <PreviewDocumentDialog
          isOpen={isModalOpen && selectedReportType === 3}
          onClose={onClosePreviewModal}
          reportName={watch('reportName')}
          locations={locations?.results || []}
          pdfReportData={pdfReportData}
          reportPeriodType={reportPeriodType}
          deviceTypes={selectedDeviceTypes}
          energyType={selectedEnergyType}
          measurementTypes={selectedMeasurementTypes}
          totalDetailed={selectedTotalDetailed}
          groupId={devicesGroupsSelect}
          deviceIds={selectedDevices}
          ibexIndex={watch('ibexPrice')}
          carbonIntensity={watch('carbonIntensity')}
          startDate={watch('startDate')}
          reportType={watch('selectedReportType')}
          endDate={watch('endDate')}
        />
      </Grid>
    </>
  )
}

export default GenerateReport

// import { format } from "path"
import { Divider, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { format } from 'date-fns'
import React from "react"
import { TruncatedText } from "../../../../../app/components/Text/TruncatedText"
import { getReportNameById } from "../../../../../utils/helpers"
import { ReportHistoryData } from "../../../types"
import OverflowButton from "../OverflowButton"

type Props  ={
    reportsHistory: ReportHistoryData.ReportHistory[]
}
export const ReportHistoryTemplateRow = ({reportsHistory}: Props) => {

    return (
        <>
        {reportsHistory?.map((rh, index) => (
          <Grid templateColumns={`repeat(2, 1fr)`} key={`${index}`}>
            <GridItem py="16px">
              <Flex
                justifyContent="left"
                fontSize="14px"
                fontWeight="700"
                lineHeight="18px"
                direction="column"
                gap={1}
              >
                <Text>{rh.filters.report_name}</Text>
                <Text color="text.dark">
                  {format(new Date(rh.from_date), 'dd MMM yyyy')} -{' '}
                  {format(new Date(rh.to_date), 'dd MMM yyyy')}
                </Text>
                <Text color="text.dark">
                  {getReportNameById(Number(rh.filters.report_type))}
                </Text>
                <TruncatedText text={rh.locations.map((x) => x.title).join(', ')} />
              </Flex>
            </GridItem>
            <GridItem pr="24px">
              <Flex justifyContent="right" align="center" h="100%">
                <OverflowButton record={rh} />
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
              <Divider />
            </GridItem>
          </Grid>
        ))}
        </>
    )
}